    import { Component, OnInit ,OnDestroy   ,AfterViewChecked} from '@angular/core';
    import { MatTabChangeEvent } from '@angular/material/tabs';
    import { CustomerService } from 'src/app/pages/customer/services/customer.service';
    import { PerformancetabService } from 'src/app/pages/customer/services/performancetab.service';
    
    @Component({
      selector: 'app-customerleftwindow',
      templateUrl: './customerleftwindow.component.html',
      styleUrls: ['./customerleftwindow.component.css'],
    })
    export class CustomerleftwindowComponent implements OnInit , AfterViewChecked , OnDestroy {
      public assetsReported: any;
      public assetCount: string ="0";
      public _atmCount: number=30;
      public isLoading: boolean = true;
      public customer_id ;
      public customer_logo ;
      
    
    
      constructor(
        private _customerService$: CustomerService,
        private _performanceTabService$: PerformancetabService) {
          this.assetCount="0";

        }
     
      ngOnInit(): void {
        this.customer_id=localStorage.getItem('customer_id');
        this.customer_logo=localStorage.getItem('customer_logo');
        this.atmCount();
        //this.getAssetCount();
        this.assetCount="0";
        
        //alert(this.customer_id)
      }

      //Setting the value of Total asset count from customer dashboard
      ngAfterViewChecked(){
        this.assetCount="0";
        this.assetCount=this._customerService$.dashboardAtmCount;

      }

      /**
       * On change get number of atms reported
       */
    
      onChange(event: MatTabChangeEvent) {
        const tab = event.tab.textLabel;        
        console.log(tab);        
        if (tab === '30 Days') {
          this._atmCount = 30;
          this.assetsReported = 0 ;
          this.atmCount();
          console.log(this._atmCount);
        }
    
        if (tab === '7 Days') {
          this._atmCount = 7;
          this.assetsReported = 0 ;
          this.atmCount();
          console.log(this._atmCount, );
        }
    
    
        if (tab === 'Today') {
          this._atmCount = 1;
          this.assetsReported = 0 ;
          this.atmCount();
          console.log(this._atmCount);
        }
        
      };
    
      /**
       * getting the atm count
       */
     
      public atmCount = (): void => {
        
        this._performanceTabService$
          .takeAtmCount({date_filter:this._atmCount, customer_id: this.customer_id})
          .subscribe({
            next: (response: any) => {
              console.log(response);
    
              this.assetsReported = response.data.atm_counts;
            },
            error: (err: Error) => {
              console.log(err['error'].message);
            },
            complete: () => {
              this.isLoading = false;
            },
          });
        
      };


  //  getting totaLAssetCount from customerdashboard api
  public getAssetCount = (): void => {
    this._customerService$.listAllTilesAPI({ customer_id: this.customer_id}).subscribe({
      next: (response: any) => {
        if (response.Code === 200) {
          this.assetCount = response.data.asset_count;
          console.log(this.assetCount, 'assetcount_show');
        }
      },
      error: (err: Error) => {
        console.error(err['error'].message);
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  };
  
  ngOnDestroy() {
    this.assetCount="0";
  }

    }