import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SwitchUserService } from './switch-user.service'
import { debounceTime, tap, switchMap, finalize, distinctUntilChanged, filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-switch-user',
  templateUrl: './switch-user.component.html',
  styleUrls: ['./switch-user.component.css']
})

export class SwitchUserComponent implements OnInit {

  isLoading = false;
  isMatLoading = false;
  myControl = new FormControl('');
  options = [];
  filteredOptions: Observable<string[]>;
  availableCustomer = []
  customerList = [];
  cust = [];
  constructor(
    private service: SwitchUserService,
    private _router: Router,
    private _snack: SnackbarService
  ) { }

  ngOnInit() {
    this.getCustomerList();
  }


  getCustomerList(){
    this.isLoading = true;
    this.service.getCustomerList({ searchkey: '', 'role': localStorage.getItem('role'), 'user_id': localStorage.getItem('user_id') }).subscribe({
      next: (response: any) => {
        if (response.code == 200) {
          this.customerList = response.customer_list
        } 
      },
      error: (err: Error) => {
        this.isLoading = false;
        console.error(err['error'].message);
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }


  switchUser() {
    this.switchLocalStorage(this.myControl.value);
  }

  switchLocalStorage(customerId) {
    this.isLoading = true;
    this.service.checkUserType({ 'customer_id': customerId }).subscribe({
      next: (response: any) => {
        if (response.code === 200) {
         const res = this.customerList.find((customer) => customer.CustomerId == customerId);

          this.isLoading = false;
          localStorage.setItem('isSwitched', 'true');
          localStorage.setItem('customer_id', customerId);
          localStorage.setItem('temp_role', localStorage.getItem('role'));
          localStorage.setItem('admin_username', localStorage.getItem('username'));
          localStorage.setItem('username', res.UserName);
          localStorage.setItem('temp_userId', res.UserId);
          localStorage.setItem('customer_logo', res.CustomerLogo);
          localStorage.setItem('institute_id', res.institute_id);
          if (response.status) {
            localStorage.setItem('role', 'CA');
            this._router.navigate(['dashboard']);
            //this._router.navigate([this.routers.DASHBOARD]);
          } else {
            localStorage.setItem('role', 'C');
            this._router.navigate(['customer']);
          }

        }
      },
      error: (err: Error) => {
        this.isLoading = false;
        this._snack.notifyError('Unable to switch', 'OK');
      },
      complete: () => {
        this.isLoading = false;

      },
    });
  }

}
