import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PerformancetabService {
  constructor(public http: HttpClient) { }

  /**
   * Atm Count
   */
  public takeAtmCount = (params): Observable<any> => {
    return this.http
      .post(environment.baseURL + "/atm-count"+ environment.api_postfix,params,{})
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: Error) => {
          throw err;
        })
      );
  };
}
