import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  @Output() sendLoginForm = new EventEmitter<void>();
  public form: FormGroup;
  public flatlogicEmail = '';
  public flatlogicPassword = '';
  public routers;
  public error_message = "";
  public submitted = false;
  showPassword = false;
  isLoading = false;
  constructor(
    private router: Router,
    private service: AuthService,
  ) { }




  public ngOnInit(): void {
    this.form = new FormGroup({
      email: new FormControl("", [Validators.required]),
      password: new FormControl("", [Validators.required]),
    });
  }

  public login(): void {

    console.log(this.form);
    if (this.form.valid) {
      this.isLoading = true;
      this.submitted = false;
      let params = {
        "username": this.form.controls.email.value,
        "password": this.form.controls.password.value,


      }
      this.service.login(params).subscribe((response) => {

        if (response.code == 200) {
          this.isLoading = true;
          localStorage.setItem('token_id', response?.data?.id_token);
          localStorage.setItem('refresh_token', response?.data?.refresh_token);
          localStorage.setItem('access_token', response?.data?.access_token);
          localStorage.setItem('expires_in', response?.data?.expires_in);
          localStorage.setItem('role', response?.data?.user?.user_role);
          localStorage.setItem('customer_id', response?.data?.user?.customer_id);
          localStorage.setItem('username', response?.data?.user?.username);
          localStorage.setItem('customer_logo', response?.data?.user?.customer_logo);
          localStorage.setItem('user_id', response?.data?.user?.user_id);
          localStorage.setItem('institute_id', response?.data?.user?.institute_id);
          localStorage.setItem('connection_pool', response?.data?.connection_pool);
          this.sendLoginForm.emit();
        }
        else {
          if (response.code == 403) {
            this.isLoading = false;
            localStorage.setItem('username', this.form.controls.email.value);
            this.router.navigate(['otp-verification']);
          } else {
            this.isLoading = false;
            this.error_message = response.message
          }

        }
      });

    } else {
      this.isLoading = false;
      this.submitted = true;

    }
  }

  public goToForgotPassForm() {
    this.router.navigate(['forgot-password']);
    return;
  }

  ToggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
}
