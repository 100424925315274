import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from "@angular/router"
import { User } from '../../../../pages/auth/models';
import { HeaderService } from '../../services/header.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  @Input() user: User;
  @Output() signOut: EventEmitter<void> = new EventEmitter<void>();
  public flatlogicEmail: string = "https://flatlogic.com";
  public user_id: string;
  public user_type: string;
  public isLoading = false;
  public linkedUsersList: [] = [];
  isSwitched = '';

  constructor(private headerService: HeaderService,
    private router: Router) { }

  public signOutEmit(): void {
    this.signOut.emit();
  }

  ngOnInit(): void {
    this.user_id = localStorage.getItem('user_id');
    this.user_type = localStorage.getItem('role');
    this.isSwitched = localStorage.getItem('isSwitched');
  }

  switchBacktoAdmin() {

    localStorage.setItem('username', localStorage.getItem('admin_username'));
    localStorage.setItem('customer_id', '');
    localStorage.setItem('customer_logo', localStorage.getItem('admin_username'));
    localStorage.setItem('role', localStorage.getItem('temp_role'));
    localStorage.setItem('isSwitched', 'false');
    localStorage.removeItem('isSwitched');
    localStorage.removeItem('admin_username');
    localStorage.removeItem('temp_role');
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['dashboard']);

  }


  switchToCustomer() {
    this.router.navigate(['switch']);
  }





  getLinkedAccounts() {
    this.isLoading = true;
    this.headerService.get_linked_user_accounts({ user_id: this.user_id }).subscribe(response => {
      if (response) {
        this.isLoading = false;
        this.linkedUsersList = response.data;
      }
    })
  }

  onSwitchAccount(id) {
    localStorage.setItem('customer_id', id);
    this.reloadCurrentRoute();
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
}
