import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest } from "@angular/common/http";

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

  constructor() { }

  intercept(req: any, next: any){
    let tokeniuzeRequest = req.clone({
      setHeaders: {
        Authorization: `Bearer ${localStorage.getItem('token_id')}`,      
      }
    });

    //Added connection Pool params in all post response
    const reqCloned =  this.handleBodyIn(tokeniuzeRequest, localStorage.getItem('connection_pool'), 'connection_pool');
    const copiedReq = reqCloned;
    return next.handle(copiedReq);
  }

  handleBodyIn(req:HttpRequest<any>, appendParamValue, appendParamKey) {
    if (req.method.toLowerCase() === 'post') {
      if (req.body instanceof FormData) {
        req =  req.clone({
          body: req.body.append(appendParamKey, appendParamValue)
        })
      } else {
        const foo = {}; foo[appendParamKey] = appendParamValue;
        req =  req.clone({
          body: {...req.body, ...foo}
        })
      }            
    }    
    return req;    
  }
}