import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {

  public dashboardAtmCount = "0";


  constructor(public http: HttpClient) {}

 
  /*
   * list of TILES
   */
  public listAllTilesAPI = (params): Observable<any> => {
    return this.http
      .post(environment.baseURL + "/admin-customer-dashboard"+ environment.api_postfix,params,{})
      .pipe(
        map((response: any) => {
          this.dashboardAtmCount = response.data.asset_count;
          return response;
        }),
        catchError((err: Error) => {
          throw err;
        })
      );
  };
}
