import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
@Component({
  selector: 'app-forget-pass',
  templateUrl: './forgetpass-form.component.html',
  styleUrls: ['./forgetpass-form.component.scss']
})
export class ForgetPassComponent implements OnInit {
  public todayDate: Date = new Date();
  public form: FormGroup;
  public error_message="";
  public submitted =false ;
  isLoading=false;
  constructor(
    private _router: Router,
    private _service: AuthService,
    private _snack: SnackbarService
  ) { 

  }

  ngOnInit(): void {
    this.form = new FormGroup({
      username: new FormControl('', [Validators.required]),
    });
  }

  resendVerificationCode(){
    if (this.form.valid) {
      this.isLoading = true;
      this.submitted=false;
      let params = {
        "username":this.form.controls.username.value,
      }

     this._service.resendVerificationCode(params).subscribe((response)=>{

      if(response.code==200){
        this.isLoading = false;
        this._snack.notifySuccess(response.message, 'Success');
        this._router.navigate(['reset-password']);
      }
      else{
        this.isLoading = false;
          this.error_message = response.message
      }
     });
    }else{
      this.isLoading = false;  
      this.submitted=true;
      
    }

  }

  

}
