import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

const headers = new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Access-Control-Allow-Origin', '*');

@Injectable({
  providedIn: 'root'
})
export class SwitchUserService {

  constructor(public _http: HttpClient) { }

  public getCustomerList(params):Observable<any>{
    return this._http.post(environment.baseURL + "/switch-user-customer-list"+ environment.api_postfix,params,{});
  }


  public checkUserType(params):Observable<any>{
    return this._http.post(environment.baseURL + "/check-user-type"+ environment.api_postfix,params,{});
  }
 


}
