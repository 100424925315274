<mat-toolbar class="header"> 

  <div class="header__title">
    <!-- <button class="header__title-button" mat-mini-fab (click)="openMenu()">
      <mat-icon *ngIf="!isMenuOpened" class="header__title-button-icon">menu</mat-icon>
      <mat-icon *ngIf="isMenuOpened" class="header__title-button-icon">arrow_back</mat-icon>
    </button> -->
    <h6 class="header__title-text"><img src="./assets/images/cams-logo-black.png" alt=""></h6>
  </div>
  <div class="serachArea"  >
   <form #searchForm="ngForm" *ngIf="user_type=='C' || user_type=='CA' ">
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-icon>search</mat-icon>

      <input matInput placeholder="Search Asset Id, Name, Location" 
      [matAutocomplete]="auto" 
      (keyup)="search_result(searchForm)"
      (click)="search_result(searchForm)"
       name="keyword" ngModel 
       
       >
       <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"  (optionSelected)="navToProd($event)">
        <mat-option  *ngFor="let items of search" [value]="items">
          {{items.InstitutionAssignedID}} -- {{items.LocationDescription}} 
          -- {{items.City}} -- {{items.StateCode}}
        </mat-option>       
       </mat-autocomplete>
       <!-- <mat-icon (click)="clearSearch()"> cancel_presentation</mat-icon> -->
       <i *ngIf="searchKeyword !=''" class="fa fa-times clear-search" (click)="clearSearch()" aria-hidden="true"></i>
    </mat-form-field>
    </form>
  </div>
  <div class="header-toolbar">
    {{keyword}}
    <app-search></app-search>
    <app-user [user]="user$ | async" (signOut)="signOut()"></app-user>
  </div>

</mat-toolbar>
