import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { NgxEchartsModule } from 'ngx-echarts';
import { TrendModule } from 'ngx-trend';
import { MatSortModule } from '@angular/material/sort';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';

import { DashboardPageComponent } from './containers';
import {
  
  RevenueChartComponent,
} from './components';

import { DashboardService } from './services';
import { DashboardComponent } from './components/dashboard/dashboard.component';

/*
* Shared Module
*/
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [
   
    RevenueChartComponent,
    DashboardPageComponent,
    DashboardComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    NgxEchartsModule,
    TrendModule,
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatProgressBarModule,
    MatToolbarModule,
    MatGridListModule,
    MatSelectModule,
    MatInputModule,
    MatSortModule,
    FormsModule,
    SharedModule
  ],
  exports: [
  ],
  providers: [
    DashboardService
  ]
})
export class DashboardModule { }
