<app-loader *ngIf="isLoading"></app-loader>
<form class="form" [formGroup]="form" (ngSubmit)="login()">

  <div  *ngIf="error_message != '' ">
    <div class="alert alert-danger" role="alert" >
      {{error_message}}
    </div>
  </div>
  <mat-form-field class="form__input"  appearance="fill">
    <mat-label></mat-label>
      <input matInput  type="email" formControlName="email" placeholder="Username">    
    </mat-form-field>
    <div  *ngIf="(form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched))|| (form.controls['email'].invalid && submitted)">
      <div class="alert alert-danger" role="alert" *ngIf="form.controls['email'].errors.required">
        Please enter username
      </div>
    </div>
    <!--<div *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)" class="alert">
          <div *ngIf="form.controls['email'].errors.required">
          Please enter username
        </div>
      </div>-->

  <mat-form-field class="form__input show-password-icon"  appearance="fill">
    <mat-label></mat-label>
    <input matInput  [type]="showPassword ? 'text' : 'password'"  formControlName="password" placeholder="Password">
    <mat-icon (click)="ToggleShowPassword()" *ngIf="showPassword">visibility</mat-icon>
    <mat-icon (click)="ToggleShowPassword()" *ngIf="!showPassword">visibility_off</mat-icon>
    
  </mat-form-field>
  <div  *ngIf="(form.controls['password'].invalid && (form.controls['password'].dirty || form.controls['password'].touched)) || (form.controls['password'].invalid && submitted)">
    <div class="alert alert-danger" role="alert" *ngIf="form.controls['password'].errors.required">
      Please enter password
    </div>
  </div>
  
  <a class="forgot-password" routerLink="/forgot-password">Forgot Password?</a>
  <div class="form-actions submit-btn-div">
    <button class="form-actions__login" mat-raised-button color="primary"  type="submit">Login</button>
    <!--<a class="form-actions__forget" mat-button (click) = "goToForgotPassForm()">Forget password</a>-->
  </div>
  
</form>
