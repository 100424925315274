import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { AuthPageComponent } from './containers';
import { OtpVerificationComponent } from './components/otp-verification/otp-verification.component';
import { ForgetPassComponent } from './components/forget-pass/forgetpass-form.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';

const routes: Routes = [
  {
    path: '',
    component: AuthPageComponent
  },
  {
    path: 'otp-verification',
    component: OtpVerificationComponent
  },

  {
    path: 'forgot-password',
    component: ForgetPassComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})

export class AuthRoutingModule {
}
