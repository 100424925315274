<div class="notFoundPage">
    <div class="notFountContent">
        <div class="notFoundTxtDv">
            <img src="not-found-icon.png" alt="">
            <div class="notFoundTxt">
                <h2>We are sorry !</h2>
                <p> System can not find the file you are looking for, Please contact your account administrator at support@camscompanion.com</p>
            </div>
        </div>
    </div>
</div>
