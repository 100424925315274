import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CoreEnvironment } from '@angular/compiler/src/compiler_facade_interface';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpInterface } from 'src/app/shared/services/interfaces/http.interface';
import { environment } from 'src/environments/environment';

const headers = new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Access-Control-Allow-Origin', '*');

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  constructor(public http: HttpClient) {}

  /*
   * list the assets
   */
  public listAllAssetsAPI = (param): Observable<any> => {


    let role = localStorage.getItem('role');
    let apiUrl  = '';
    if(role == 'CA'){
      apiUrl = '/asset-list-for-customer-admin'
    }else{
      apiUrl = '/asset-list-customer'
    }
    
    return this.http
      .post(`${environment.baseURL}${apiUrl}${environment.api_postfix}`,param,{})
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: Error) => {
          throw err;
        })
      );
  };

  /*
   * Asset Filters
   */
  public assetFiltersAPI = (param): Observable<any> => {
    return this.http
      .post(environment.baseURL + "/asset-list-filter"+ environment.api_postfix,param,{})
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: Error) => {
          throw err;
        })
      );
  };

  /*
   * Asset Profile
   */
  public assetProfileAPI = (param): Observable<any> => {
    return this.http
      .post(environment.baseURL + "/asset-profile"+ environment.api_postfix,param,{})
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: Error) => {
          throw err;
        })
      );
  };

  /*
   * Asset list Action items
   */
  public assetListActionItemsAPI = (param): Observable<any> => {
    return this.http
      .post(environment.baseURL + "/asset-list-action-items"+ environment.api_postfix,param,{})
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: Error) => {
          throw err;
        })
      );
  };

  /*
   * Asset list images
   */
  public assetListImagesyAPI = (param): Observable<any> => {
    return this.http
      .post(environment.baseURL + "/asset-list-image"+ environment.api_postfix,param,{})
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: Error) => {
          throw err;
        })
      );
  };

  /*
   * Asset list history
   */
  public assetListHistoryAPI = (param): Observable<any> => {
    return this.http
      .post(environment.baseURL + "/asset-list-history"+ environment.api_postfix,param,{})
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: Error) => {
          throw err;
        })
      );
  };
  
  public ignoreItems =(param): Observable<any> => {
    return this.http
      .post(environment.baseURL + "/ignore-issue"+ environment.api_postfix,param,{})
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: Error) => {
          throw err;
        })
      );
  };
  /**
   * 
   * Email Asset
   */
  
  public EmailAsset =(param): Observable<any> => {
    return this.http
      .post(environment.baseURL + "/send-asset-email"+ environment.api_postfix,param,{})
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: Error) => {
          throw err;
        })
      );
  };

  /**
   *Asset Search
   */
  public search = (param): Observable<any> => {
    return this.http
      .post(environment.baseURL + "/global-search-customer"+ environment.api_postfix,param,{})
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: Error) => {
          throw err;
        })
      );
  };


  /**
   * 
   * fetach All the records
   * to be exported in excel
   * 
   */
  public exportAssetsAPI = (param): Observable<any> => {
    return this.http
      .post(environment.baseURL + "/asset-customer-list-export-to-excel"+ environment.api_postfix,param,{})
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: Error) => {
          throw err;
        })
      );
  };


}
