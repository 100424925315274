<app-layout>
    <div *ngIf="isLoading" style="
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    ">
        <mat-spinner color="primary"></mat-spinner>
    </div>

    <div class="customer_Right  approval_Right jobSecRgt">
        <div class="row">
            <div class="col-12">
                <div class="approValToolSec d-flex">
                    <div class="tabPanelSec w-100">
                        <mat-tab-group [(selectedIndex)]="jobTabIndex">
                            <mat-tab label="{{totalrecordFound}}" class="nonClck"> </mat-tab>
                            <mat-tab label="Assignment Queue">
                                <div class="assQueDv">
                                    <div class="selectRowDv">
                                        <!-- <div class="Slctcol" hidden>
                                            <div class="jobTopcol">
                                                <select class="slctDd">
                                                    <option value="">Customer</option>
                                                </select>
                                            </div>
                                            <div class="jobDataCol">Brighton Bank</div>
                                        </div> -->
                                        <div class="Slctcol">
                                            <div class="jobTopcol">
                                                <select class="slctDd" #jobStatusSelect
                                                    (change)="filterByDataCollect(jobStatusSelect.value, 'job_status', $event)">
                                                    <option value="">Job Status</option>
                                                    <option *ngFor="let jobStatusItem of jobStatusLookup"
                                                        value="{{jobStatusItem.JobStatusId}}"
                                                        text="jobStatusItem.JobStatus">
                                                        {{jobStatusItem.JobStatus}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="jobDataCol">{{jobStatusText}}</div>
                                        </div>
                                        <div class="Slctcol">
                                            <div class="jobTopcol">
                                                <select class="slctDd" #jobYearSelect
                                                    (change)="filterByDataCollect(jobYearSelect.value, 'job_year', $event)">
                                                    <option value="">Job Year</option>
                                                    <option *ngFor="let jobYearItem of jobYearLookup"
                                                        value="{{jobYearItem}}"> {{jobYearItem}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="jobDataCol">{{jobYearText}}</div>
                                        </div>
                                        <div class="Slctcol">
                                            <div class="jobTopcol">
                                                <select class="slctDd" #jobMonthSelect
                                                    (change)="filterByDataCollect(jobMonthSelect.value, 'job_month', $event)">
                                                    <option value=""> Job Month</option>
                                                    <option value="01"> January</option>
                                                    <option value="02"> February</option>
                                                    <option value="03"> March</option>
                                                    <option value="04"> April</option>
                                                    <option value="05"> May</option>
                                                    <option value="06"> June</option>
                                                    <option value="07"> July</option>
                                                    <option value="08"> August</option>
                                                    <option value="09"> September</option>
                                                    <option value="10"> October</option>
                                                    <option value="11"> November</option>
                                                    <option value="12"> December</option>
                                                </select>
                                            </div>
                                            <div class="jobDataCol">{{jobMonthText}}</div>
                                        </div>
                                        <div class="Slctcol">
                                            <div class="jobTopcol">
                                                <select class="slctDd" #jobNumberSelect
                                                    (change)="filterByDataCollect(jobNumberSelect.value, 'job_no', $event)">
                                                    <option value="">Job Number</option>
                                                    <option *ngFor="let jobIdItem of jobIdLookup"
                                                        value="{{jobIdItem.job_id}}"
                                                        text="jobStatusItem.job_number">
                                                        {{jobIdItem.job_number}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="jobDataCol">{{jobNumberText}}</div>
                                        </div>
                                        <div class="Slctcol">
                                            <div class="jobTopcol">
                                                <a href="javascript:void(0)" class="btnJob" (click)="openActionModal('create')">CREATE JOB</a>
                                            </div>
                                            <div class="jobDataCol">&nbsp;</div>
                                        </div>

                                    </div>
                                    <div class="row d-flex align-items-center" >
                                        <div class="col-md-6">
                                            <div class="job-PieDv">
                                                <div class="smallPieJob">
                                                    <span class="pieArw" (click)="toggleShowDiv()" *ngIf="animationState==='out'">
                                                        <mat-icon>keyboard_arrow_down</mat-icon>
                                                    </span>
                                                    <span class="pieArw" (click)="toggleShowDiv()" *ngIf="animationState==='in'">
                                                        <mat-icon>keyboard_arrow_up</mat-icon>
                                                    </span>
                                                    <div class="jobPieCont" *ngIf="animationState==='out'">
                                                        <img src="./assets/images/pieSml.png" alt="">
                                                    </div>
                                                </div>
        
                                                <div class="expandPieJob" [@slideInOut]="animationState">
                                                    <div class="jobPieCont" *ngFor="let graph of graphItems; let i = index">
                                                        <app-pie-chart [pieChartData]="graph.pieChartData$"></app-pie-chart>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6" [@slideInOut]="animationState"> 
                                            <div class="graph_right">
                                                <div class="percentList" *ngFor="let graph of graphItems; let i = index">
                                                    <span class="color" [style.background-color]="graph.ColorCode"></span>
                                                    <span>{{graph.KpiCount}}: {{graph.IssueCategory}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tableDv approvalTblDv JobTblDv">
                                        <table mat-table [dataSource]="dataSource" matSort>
                                            <ng-container matColumnDef="assetID">
                                              <th mat-header-cell *matHeaderCellDef mat-sort-header>Asset Id</th>
                                              <td mat-cell *matCellDef="let element">
                                                <a title="{{ element.assetID }}" href="javascript:void(0)" (click)="openProfilelDetails(element.JobId, element.ATMID)">{{ element.assetID }}</a>
                                              </td>
                                            </ng-container>

                                            <ng-container matColumnDef="atmDescription">
                                              <th mat-header-cell *matHeaderCellDef mat-sort-header>ATM Description</th>
                                              <td mat-cell *matCellDef="let element">{{ element.atmDescription }}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="address">
                                              <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
                                              <td mat-cell *matCellDef="let element">{{ element.address }}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="city">
                                              <th mat-header-cell *matHeaderCellDef mat-sort-header>City</th>
                                              <td mat-cell *matCellDef="let element">{{ element.city }}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="state">
                                              <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
                                              <td mat-cell *matCellDef="let element">{{ element.state }}</td>
                                            </ng-container>
                                    
                                            <ng-container matColumnDef="zip">
                                              <th mat-header-cell *matHeaderCellDef mat-sort-header>Zip</th>
                                              <td mat-cell *matCellDef="let element">{{ element.zip }}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="assignedDate">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Assigned Date</th>
                                                <td mat-cell *matCellDef="let element">{{
                                                    element.assignedDate?(element.assignedDate | date:'MM/dd/yyyy'):'N/A' }}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="dueDate">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Due Date</th>
                                                <td mat-cell *matCellDef="let element">{{
                                                    element.dueDate?(element.dueDate | date:'MM/dd/yyyy'):'N/A' }}</td>
                                            </ng-container>
                                    
                                            <ng-container matColumnDef="serviceInstanceStatus">
                                              <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                                              <td mat-cell *matCellDef="let element">{{ element.serviceInstanceStatus }}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="service">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Services</th>
                                                <td mat-cell *matCellDef="let element">{{ element.service }}</td>
                                            </ng-container>
                                            <!-- Edit Column -->
                                            <ng-container matColumnDef="editJob">
                                                <th mat-header-cell *matHeaderCellDef> Edit Job </th>
                                                <td mat-cell *matCellDef="let element">
                                                <button mat-icon-button color="primary" (click)="openActionModal('edit', element.JobId)">
                                                    <mat-icon>edit</mat-icon>
                                                </button>
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                                        </table>
                                        <div *ngIf="!totalrecordFound" style="text-align: center; padding-top: 16px;">No data found</div>
                                        <mat-paginator *ngIf="totalrecordFound" [pageSizeOptions]="[10, 20, 50, 100]" [length]="totalrecordFound" 
                                        [pageSize]="10" (page)="onPaginateChange($event)"></mat-paginator>
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab label="Assignment Profile">
                                <div class="assProfDv">
                                    <div class="selectRowDv">
                                        <div class="assprofbtncol">
                                            <!-- <a href="javascript:void(0)" class="btnJob" (click)=" openActionModal('create')">CREATE JOB</a> -->
                                            <!-- <a href="javascript:void(0)" class="btnJob">OPEN JOB</a> -->
                                            <a href="javascript:void(0)" (click)=" openActionModal('reassign')" class="btnJob">OPEN TICKET</a>
                                        </div>
                                    </div>
                                    <div class="tableDv approvalTblDv assproTblDv" border="0">
                                        <table cellpadding="0" cellspacing="0">
                                            <tr *ngFor="let profile of profileDataSource?.filteredData; let i = index">
                                                <td>
                                                    <table cellpadding="0" cellspacing="0" class="assProfInnerTable">
                                                        <tr>
                                                            <td class="twoColTd">
                                                                <div class="jobInnerTbl">
                                                                    <img _ngcontent-mmr-c339="" alt=""
                                                                        src="./assets/images/img11.jpg" class="img50">
                                                                    <div class="imgDtlDv">
                                                                        <h4>{{profile.ServiceInstanceId}}</h4>
                                                                        <h6>{{profile.atmDescription}}</h6>
                                                                        <p>{{profile.address}}<br>{{profile.city}} {{profile.state}} {{profile.zip}}</p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <span>Assigned Date</span>
                                                                <mat-icon role="img"
                                                                    class="mat-icon notranslate material-icons mat-icon-no-color"
                                                                    aria-hidden="true">keyboard_arrow_down</mat-icon>
                                                            </td>
                                                            <td>
                                                                <span>Due Date</span>
                                                                <mat-icon role="img"
                                                                    class="mat-icon notranslate material-icons mat-icon-no-color"
                                                                    aria-hidden="true">keyboard_arrow_down</mat-icon>
                                                            </td>
                                                            <td>
                                                                <span>Completion Date</span>
                                                                <mat-icon role="img"
                                                                    class="mat-icon notranslate material-icons mat-icon-no-color"
                                                                    aria-hidden="true">keyboard_arrow_down</mat-icon>
                                                            </td>
                                                            <td>
                                                                <span>Status</span>
                                                                <mat-icon role="img"
                                                                    class="mat-icon notranslate material-icons mat-icon-no-color"
                                                                    aria-hidden="true">keyboard_arrow_down</mat-icon>
                                                            </td>
                                                            <td>
                                                                <span>Service(S)</span>
                                                            </td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                        </tr>
                                                        <tr class="indentRow">
                                                            <td> <img _ngcontent-mmr-c339="" alt=""
                                                                    src="./assets/images/img11.jpg" class="img50"></td>
                                                                    <td>{{
                                                                        profile.AssignedDate?(profile.AssignedDate | date:'MM/dd/yyyy'):'N/A' }}</td>
                                                                    <td>{{
                                                                        profile.DueDate?(profile.DueDate | date:'MM/dd/yyyy'):'N/A' }}</td>
                                                                    <td>{{
                                                                        profile.CompletionDate?(profile.CompletionDate | date:'MM/dd/yyyy'):'N/A' }}</td>
                                                            <td>{{profile.ServiceInstanceStatus}}</td>
                                                            <td>{{profile.ServiceName}}</td>
                                                            <td>
                                                                <form>
                                                                    <div class="SlctRow">
                                                                        <select class="slctDd actionslect" 
                                                                        id="action_cls_{{ i }}" (change)="
                                                                        openActionModal(
                                                                          $event.target.value)">
                                                                            <option selected="true" disabled="disabled">Action</option>
                                                                            <option value="approve" *ngIf="profile.ServiceInstanceStatus !== 'Assigned'">Approve</option>
                                                                            <option value="reassign">Reassign</option>
                                                                            <option value="cancel">
                                                                                Cancel
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </form>
                                                            </td>
                                                            <td>
                                                                <button class="mrInfoBtn" mat-stroked-button color="primary" (click)="
                                                                                                            clicktoseehistory(i, profile.ServiceInstanceId)
                                                                                                          ">
                                                                    <span class="mrInfoIcn">
                                                                        <img src="./assets/images/mrInfBtn.png" alt="" />
                                                                    </span>
                                                                    <!-- More Info
                                                                                                          <mat-icon>chevron_right</mat-icon> -->
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table
                                                    class="myhistory"
                                                    border="0"
                                                    cellspacing="0"
                                                    width="100%"
                                                    cellpadding="0"
                                                    id="history_details_{{ i }}"
                                                    style="display: none"
                                                >
                                                    <tr>
                                                    <td>
                                                        <div>
                                                        <table
                                                            border="0"
                                                            cellspacing="0"
                                                            width="100%"
                                                            cellpadding="0"
                                                            class="mrInfoTbl"
                                                        >
                                                            <tr>
                                                            <th>Question</th>
                                                            <th>Answer</th>
                                                            <th>Images</th>
                                                            </tr>
                                                            <!-- <ng-container
                                                            *ngIf="
                                                                item.question_list.length > 0;
                                                                then show_question_list;
                                                                else dontShow_question_list
                                                            "
                                                            ></ng-container> -->
                                                            <ng-template #show_question_list>
                                                            <tr
                                                                *ngFor="
                                                                let question of assetHistoryList;
                                                                let i = index
                                                                "
                                                            >
                                                                <td>{{ question.Question }}</td>
                                                                <td>{{ question.Answer }}</td>
                                                                <td>
                                                                <img
                                                                    (click)="
                                                                    historyImageView(question)
                                                                    "
                                                                    style="cursor: pointer"
                                                                    src="{{ baseUrl }}images/{{
                                                                    question.JobId
                                                                    }}/{{ question.ATMID }}/{{
                                                                    question.ImageId
                                                                    }}.{{ question.Extension }}"
                                                                    alt=""
                                                                />
                                                                </td>
                                                            </tr>
                                                            </ng-template>
                                                            <ng-template #dontShow_question_list>
                                                            <tr>
                                                                <td>No Data found</td>
                                                            </tr>
                                                            </ng-template>
                                                        </table>
                                                        </div>
                                                    </td>
                                                    </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <div *ngIf="!assignmentprofileTotalRecord" style="text-align: center; padding-top: 16px;">No data found</div>
                                            <mat-paginator *ngIf="assignmentprofileTotalRecord" [pageSizeOptions]="[10, 20, 50, 100]" [length]="assignmentprofileTotalRecord" 
                                            [pageSize]="10" (page)="onProfilePaginateChange($event)"></mat-paginator>
                                        </table>
                                    </div>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</app-layout>