<div class="auth-page">
    <div class="auth-page__content-block">
        <div class="auth-page__content-wrapper">
            <mat-tab-group class="auth-page__group">
                <mat-tab label="OTP">
                    <h4 class="auth-page__group-title">CAMS Admin</h4>
                    <div class="auth-page__google-button-wrapper">

                    </div>


                    <form class="form" [formGroup]="form" (ngSubmit)="verifyOtp()">

                        <div *ngIf="error_message != '' ">
                            <div class="alert alert-danger" role="alert">
                                {{error_message}}
                            </div>
                        </div>
                        <mat-form-field class="form__input" appearance="fill">
                            <mat-label></mat-label>
                            <input matInput type="text" formControlName="username" placeholder="Username">
                          
                        </mat-form-field>
                        <mat-form-field class="form__input" appearance="fill">
                            <mat-label></mat-label>
                            <input matInput type="text" formControlName="otp" placeholder="OTP">
                        </mat-form-field>
                        <div
                            *ngIf="(form.controls['otp'].invalid && (form.controls['otp'].dirty || form.controls['otp'].touched))|| (form.controls['otp'].invalid && submitted)">
                            <div class="alert alert-danger" role="alert" *ngIf="form.controls['otp'].errors.required">
                                Please enter your OTP
                            </div>
                        </div>
                     
                       


                        <div class="form-actions">
                            <button class="form-actions__login" mat-raised-button color="primary"
                                type="submit">Verify OTP</button>
                            <a mat-stroked-button color="primary" routerLink="/login" class="go-to-login">Go to login</a>    
                            <!--<a class="form-actions__forget" mat-button (click) = "goToForgotPassForm()">Forget password</a>-->
                        </div>
                        <p class="small-msg"><small>Please enter the OTP sent to your registered email</small></p>
                    </form>


                </mat-tab>
              
            </mat-tab-group>

            <p class="auth-page__footer-title">© 2014-{{todayDate | year}} <a href="https://flatlogic.com/">Cams
                    admin</a>,
                LLC. All rights reserved.</p>
        </div>
    </div>
    <div class="auth-page__logo">
        <div class="auth-page__logo-wrapper">
            <div class="loginRgtLft">
                <div class="logoDv">
                    <img class="auth-page__logo-img" src="./assets/images/cams-logo-black.png" alt="logo">
                </div>
                <h1>
                    ATM<br>
                    brand<br>
                    intelligence
                </h1>
            </div>
            <div class="loginRgtRgt">
                <div class="loginImg">
                    <img class="auth-page__logo-img" src="./assets/images/laptop.png" alt="logo">
                </div>
            </div>
         
        </div>
    </div>
</div>