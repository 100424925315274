<app-layout>
    <app-loader *ngIf="isLoading"></app-loader>
    <div class="customer_Right  approval_Right jobSecRgt">
        <!-- <div class="row"> -->
        <div class="col-12">

            <div class="page-header">
                <h3 class="mdc-typography--headline3">Switch User</h3>
 
            </div>


            <mat-card>
                <mat-card-content>
                    <div class="container1">
                        <div class="row">
                            <div class="col-sm-2"></div>
                            <div class="col-sm-8">
                                <div class="form-inline">
                                    <form class="example-form form-inline">               
                                       <select class="form-control select-box" [formControl]="myControl">
                                            <option value="" selected disabled>Select Customer</option>
                                            <option *ngFor="let cust of customerList" value="{{cust.CustomerId}}">{{cust.CustomerName}}</option>
                                       </select>
                                        <button class="mat-focus-indicator mat-raised-button mat-button-base mat-primary ml-15" (click)="switchUser()">Switch</button>
                                    </form>
                                </div>

                            </div>

                        </div>
                    </div>
                </mat-card-content>
            </mat-card>


        </div>
        <!-- </div> -->
    </div>
    <app-footer></app-footer>
</app-layout>