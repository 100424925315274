import { Component, Input, OnInit } from '@angular/core';
import {
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexChart
} from "ng-apexcharts";
import { PieChartData } from 'src/app/pages/customer/models/pie-chart-data'
import { colors } from '../../consts';
import { CustomerService } from '../../pages/customer/services/customer.service';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  colors: string[];
  plotOptions: ApexPlotOptions;
};


@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css']
})
export class PieChartComponent implements OnInit {
  @Input() pieChartData: PieChartData;
  public apexPieChartOptions: Partial<ChartOptions>;
  //public colors: typeof colors = colors;
  public tilesName:any;
  constructor(private _customerService$: CustomerService){}
  public ngOnInit(): void {
    this.initChart();
  }
  public initChart(): void {
    this.apexPieChartOptions = {
      series: this.pieChartData.series,
      chart: {
        height: 220,
        type: "radialBar"
      },
      plotOptions: {
        radialBar: {
          /* hollow: {
            size: "70%"
          } */
        }
      },
      colors: this.pieChartData.colors,
      labels: this.pieChartData.labels
    }
  }
}
