<style>
 ::ng-deep .ngxImageZoomContainer > img{
  max-height: 500px;
}

</style>
<div mat-dialog-content>
  <!--<img src="{{data.imageURL}}" class="img-fluid" width="100%" >-->
  
 <lib-ngx-image-zoom
    thumbImage="{{data.imageURL}}" 
    fullImage="{{data.imageURL}}" 
    [magnification]="1"
    [enableScrollZoom]="true"
    [enableLens]="true"
    [lensWidth]="200"
    [lensHeight]="200"
></lib-ngx-image-zoom>


  
  
</div>

  <button mat-button (click)="onNoClick()" class="mat-focus-indicator sign-button mat-flat-button mat-button-base clsBtnPop"><mat-icon>close</mat-icon></button>
