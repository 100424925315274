<div *ngIf="isLoading" style="
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    ">
        <mat-spinner color="primary"></mat-spinner>
    </div>

<button class="user-button" mat-mini-fab [matMenuTriggerFor]="userMenu">
  <img src="./assets/images/user.png" alt="">
</button>
<mat-menu #userMenu="matMenu" xPosition="before">
  <div class="user-menu-title">
    <h4 class="user-menu-title__name">{{user.name}}</h4>
  </div>

  <button *ngIf="isSwitched" mat-menu-item class="user-menu__item-title" (click)="switchBacktoAdmin()">
     Back To Admin
  </button>

  <button routerLink="/switch" *ngIf="!isSwitched && (user_type === 'SA' || user_type === 'CA')" (click)="switchToCustomer()" mat-menu-item class="user-menu__item-title">
    <img src="./assets/images/user.png" alt=""> Switch User
  </button>

  <div class="sign-button-wrapper">
    <button class="sign-button" mat-flat-button (click)="signOutEmit()">Sign out</button>
  </div>
</mat-menu>
