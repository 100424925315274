import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';

import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { CustomerModule } from './pages/customer/customer.module';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AuthModule } from './pages/auth/auth.module';
import { AssetlinkModule } from './pages/assetlink/assetlink.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { IgnoreitemComponent } from './pages/ignoreitem/ignoreitem.component';
import { SendassettoemailComponent } from './pages/sendassettoemail/sendassettoemail.component';
import { ApprovalToolComponent } from './pages/approval-tool/approval-tool.component';
import { ApprovalToolAdminComponent } from './pages/approval-tool-admin/approval-tool-admin.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ImageCropperModule } from 'ngx-image-cropper';
import { JobAdminComponent } from './pages/job-admin/job-admin.component';
import { ServiceTicketModalComponent } from './pages/service-ticket-modal/service-ticket-modal.component';
import { CreateJobComponent } from './pages/create-job/create-job.component';
import { FileNotFoundComponent } from './pages/file-not-found/file-not-found.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwitchUserComponent } from './pages/switch-user/switch-user.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from './token-interceptor.service';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';



@NgModule({
  declarations: [AppComponent, NotFoundComponent, IgnoreitemComponent, SendassettoemailComponent, ApprovalToolComponent, ApprovalToolAdminComponent, JobAdminComponent, ServiceTicketModalComponent, CreateJobComponent, FileNotFoundComponent, SwitchUserComponent],
  imports: [
    BrowserModule,
    SharedModule,
    AuthModule,
    DashboardModule,
    CustomerModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    RouterModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    AccordionModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    MatCardModule,
    MatButtonModule,
    AssetlinkModule,
    NgbModule,
    ImageCropperModule,
    FormsModule,
    ReactiveFormsModule,
    SlickCarouselModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ],

  bootstrap: [AppComponent],
})
export class AppModule { }
