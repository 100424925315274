import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public todayDate: Date = new Date();
 
  public form: FormGroup;
  public error_message="";
  public submitted =false ;
  showPassword=false;
  isLoading=false;
  constructor(
    private _router: Router,
    private _service: AuthService,
    private _snack: SnackbarService
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      username: new FormControl("",[Validators.required]),
      password:  new FormControl("",[Validators.required]),
      code: new FormControl("", [Validators.required]),
    });
  }


  resetPassword(){
    if (this.form.valid) {
      this.isLoading = true;
      this.submitted=false;
      let params = {
        "username":this.form.controls.username.value,
        "password":this.form.controls.password.value,
        "code":this.form.controls.code.value,
      }

     this._service.resetPassword(params).subscribe((response)=>{
      if(response.code==200){
        this.isLoading = true;
        this._snack.notifySuccess(response.message, 'Success');
        this._router.navigate(['login']);
      }
      else{
        this.isLoading = false;
        this.error_message = response.message
      }
     });
    }else{
      this.isLoading = false;
      this.submitted=true;
      
    }
  }

  ToggleShowPassword(){
    this.showPassword=!this.showPassword;
  }

}
