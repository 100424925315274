<app-loader *ngIf="isLoading"></app-loader>
<div class="auth-page">
  <div class="auth-page__content-block">
    <div class="auth-page__content-wrapper">
      <mat-tab-group class="auth-page__group">
        <mat-tab label="Forgot Password">
          <h4 class="auth-page__group-title">CAMS Admin</h4>
            <div class="auth-page__google-button-wrapper">
          </div>
          <form class="form" [formGroup]="form" (ngSubmit)="resendVerificationCode()">
            <div *ngIf="error_message != '' ">
              <div class="alert alert-danger" role="alert">
                {{error_message}}
              </div>
            </div>
            <mat-form-field class="form__input" appearance="fill">
              <mat-label></mat-label>
              <input matInput type="text" formControlName="username" placeholder="username">
            </mat-form-field>
            <div
              *ngIf="(form.controls['username'].invalid && (form.controls['username'].dirty || form.controls['username'].touched))|| (form.controls['username'].invalid && submitted)">
              <div class="alert alert-danger" role="alert" *ngIf="form.controls['username'].errors.required">
                Please specify username
              </div>
            </div>

            <p class="small-msg"><small>Please specify your registered username to reset password</small></p>
            <div class="form-actions">
              <button class="form-actions__login" mat-raised-button color="primary" type="submit">Submit</button>
              <a mat-stroked-button color="primary" routerLink="/login" class="go-to-login">Go to login</a>
              
            </div>
          </form>
        </mat-tab>
      </mat-tab-group>
      <p class="auth-page__footer-title">© 2014-{{todayDate | year}} <a href="https://flatlogic.com/">Cams
          admin</a>,
        LLC. All rights reserved.</p>
    </div>
  </div>
  <div class="auth-page__logo">
    <div class="auth-page__logo-wrapper">
      <div class="loginRgtLft">
        <div class="logoDv">
          <img class="auth-page__logo-img" src="./assets/images/cams-logo-black.png" alt="logo">
        </div>
        <h1>
          ATM<br>
          brand<br>
          intelligence
        </h1>
      </div>
      <div class="loginRgtRgt">
        <div class="loginImg">
          <img class="auth-page__logo-img" src="./assets/images/laptop.png" alt="logo">
        </div>
      </div>

    </div>
  </div>
</div>