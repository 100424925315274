<div class="leftSec">
    <h5>Total Assets: {{assetCount}}</h5>
    <img src="{{customer_logo}}" alt="">
    <h3>Performance Overview <span class="bdrDv"></span></h3>

    <mat-tab-group class="laftTab" (selectedTabChange)="onChange($event)">
        <mat-tab label="30 Days"  > 
             <div class="laftTabContent">
        
                <table border="0" cellspacing="0" cellpadding="0">
                <tr>
                    <td>Assets Reported</td>
                    <td *ngIf="isLoading">0</td>
                    <td>{{assetsReported}}</td>
                </tr>
                <!-- <tr>
                    <td>New Issues</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>Resolved Issues</td>
                    <td>0</td>
                </tr> -->
            </table>
       
            </div>
</mat-tab>

        <mat-tab label="7 Days" >
            <div class="laftTabContent">
        
                <table border="0" cellspacing="0" cellpadding="0">
                    <tr>
                        <td>Assets Reported</td>
                        <td>{{assetsReported}}</td>
                    </tr>
                    <!-- <tr>
                        <td>New Issues</td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>Resolved Issues</td>
                        <td>0</td>
                    </tr> -->
                </table>
           
              </div>
         </mat-tab>

        <mat-tab label="Today" > 
            
            <div class="laftTabContent">
        
                <table border="0" cellspacing="0" cellpadding="0">
                    <tr>
                        <td>Assets Reported</td>
                        <td><span>{{assetsReported}}</span></td>
                    </tr>
                    <!-- <tr>
                        <td>New Issues</td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>Resolved Issues</td>
                        <td>0</td>
                    </tr> -->
                </table>
           
                </div>
        
        </mat-tab>
      </mat-tab-group>

    <!-- <ul class="laftTab">
        <li class="active">30 Days</li>
        <li>7 Days</li>
        <li>Today</li>
    </ul> -->

    <!-- <div class="laftTabContent">
        
                <table border="0" cellspacing="0" cellpadding="0">
                    <tr>
                        <td>Assets Reported</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>New Issues</td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>Resolved Issues</td>
                        <td>0</td>
                    </tr>
                </table>
           
    </div> -->


</div>
