<app-layout>
  <!-- <mat-toolbar class="page-header" role="heading">
    </mat-toolbar> -->
  <div class="customer_Right">
    <div class="row">
      <div class="col-md-3 leftSecPanel">
        <app-customerleftwindow></app-customerleftwindow>
      </div>

      <div class="col-md-9 p-0 rightSecPanel">
        <div *ngIf="isLoading" style="
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 9999;
            background-color: rgba(0,0,0,0.6);
            width: 100%;
            height: 100%;
          ">
          <mat-spinner color="primary"></mat-spinner>
        </div>

        <div class="rightSec">
          <div class="rightTop">
            <h5>
              Action items: <span>{{ actionAsset.action_items }}</span>
            </h5>
            <!--  -->
          </div>
          <div class="rightBottom">
            <h6>Dashboard</h6>
            <div class="boxDv">
              <div class="row">
                <div class="col-md-6" *ngFor="let tiles of tilesList; let i = index">
                  <div class="boxCol">
                    <h4><a href="/#/assets?category={{tiles.IssueCategory}}">{{tiles.IssueCategory}}</a><span class="bdrDv"></span></h4>
                    <mat-card-content class="row m-0 div-dashboard">
                      <mat-card-content class="col-sm-6 bdrRgt">
                        <div class="boxLeftCol">
                          <div class="bxHead">Action items</div>
                          <div class="bxImg">
                            <app-pie-chart [pieChartData]="tiles.pieChartData$"></app-pie-chart>
                          </div>
                          <div class="bxBtm">
                            <span class="bx2Clr">{{ tiles.KpiPercentage | number: "1.0-2" }} %
                            </span>
                            of assets
                          </div>
                        </div>
                      </mat-card-content>
                      <mat-card-content class="col-sm-6">
                        <div class="boxRgttCol">
                          <div class="bxHead">
                            Categories
                            <div></div>
                          </div>
                          <div class="row cateRow">
                            <div class="col-10 p-0">
                              <div class="rightTop" *ngFor="let _sCat of tiles.subCategory; let i = index">
                                <div class="row justify-content-between">
                                  <span class="col-10 NmSpan">{{_sCat.name}}:</span>
                                  <span class="col-2 bgClrSpn"><a href="/#/assets?sub_catecories={{_sCat.name}}">{{_sCat.count}}</a></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card-content>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</app-layout>