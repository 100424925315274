import { Component, OnInit } from '@angular/core';
import { routes } from '../../consts';

@Component({
  selector: 'app-file-not-found',
  templateUrl: './file-not-found.component.html',
  styleUrls: ['./file-not-found.component.css']
})
export class FileNotFoundComponent implements OnInit {
  public routes: typeof routes = routes;
  constructor() { }

  ngOnInit(): void {
  }

}
