<div *ngIf="isLoading" style="
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    ">
        <mat-spinner color="primary"></mat-spinner>
    </div>

<div class="aprvlPop reassignMentPop">

    <div class="modal-header">
        <div _ngcontent-ole-c400="" class="aprvlPopLogo">
            <img src="./assets/images/axis-logo-black-curved.png" alt="">
        </div>
        <div class="aprvlPopedtImgDv" *ngIf="title === 'reassign'">Reassign Service Ticket</div>
        <div class="aprvlPopedtImgDv" *ngIf="title === 'create'">Create Service Ticket</div>
    </div>

    <div class="modal-body">
            <div class="Acol">
                <div class="popRw">
                    <div class="row">
                        <div class="col-md-12 p-0">
                            <label>1. Select Customer<span class="required">*</span></label>
                            <div class="polCl" *ngIf="user_type === 'Customers'">
                                <select #jobCustomerSelect (change)="onCustomerSelect(jobCustomerSelect.value)">
                                    <option selected="true" disabled="disabled" value="">Customer</option>
                                    <option *ngFor="let customer of customer_list" value="{{customer.CustomerId}}"
                                        text="customer.CustomerName">
                                        {{customer.CustomerName}}
                                    </option>
                                </select>
                            </div>
                            <div class="polCl"  *ngIf="user_type !== 'Customers'">
                                <select #jobCustomerSelect (change)="onCustomerSelect(jobCustomerSelect.value)">
                                    <option selected="true" disabled="disabled" value=""> Customer</option>
                                    <option *ngFor="let customer of customer_list" value="{{customer.CustomerId}}"
                                        text="customer.CustomerName">
                                        {{customer.CustomerName}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="popRw">
                    <div class="row">
                        <div class="radioJobTypeSelection">
                            <label>2. </label>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="jobtype" id="select" value="radio1" (click)="toggleJobType()" checked>
                                <label for="select" class="form-check-label">Select Job<span class="required" *ngIf="!isCreateJob">*</span></label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="jobtype" id="create" value="radio2" (click)="toggleJobType()">
                                <label for="create" class="form-check-label">Create Job<span class="required" *ngIf="isCreateJob">*</span></label>
                            </div>
                        </div>
                        <div class="col-md-6 pl-0 clDevider">
                            <form [formGroup]="selectJobForm">
                                <div class="popLftCol">
                                    <fieldset [disabled]="isCreateJob">
                                        <div class="polColRw">
                                            <!-- <label>2. Select or Create Job</label> -->
                                            <div class="popClsDv">
                                                <div class="polCl">
                                                    <select formControlName="JobId">
                                                        <option selected="true" disabled="disabled" value=""> Job Name</option>
                                                        <option *ngFor="let name of dropdownListData?.jobNames"
                                                            value="{{name.JobId}}">
                                                            {{name.JobName}}
                                                    </option>
                                                    </select>
                                                </div>
                                                <!-- <div class="polCl">
                                                    <select>
                                                        <option value="customer"> Job Number</option>
                                                        <option *ngFor="let jobIdItem of jobIdLookup"
                                                            value="{{jobIdItem.job_id}}">
                                                            {{jobIdItem.job_number}}
                                                        </option>
                                                    </select>
                                                </div> -->
                                                <!-- <div class="clDevider">&nbsp;</div> -->

                                            </div>
                                        </div>
                                    </fieldset>
                                    <div class="polColRw">
                                        <label>3. Select Service(s)<br><small>CTRL to select multiple</small></label>
                                        <div class="polCl">
                                            <select formControlName="ServiceId">
                                                <option selected="true" disabled="disabled" value=""> Service</option> 
                                                <option *ngFor="let service of dropdownListData?.services"
                                                    value="{{service.ServiceId}}">
                                                    {{service.ServiceName}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="polColRw">
                                        <label>4. Select Technician</label>
                                        <div class="polCl">
                                            <select formControlName="TechnicianId">
                                                <option value="customer" selected="true" disabled="disabled" value=""> Technician</option>
                                                <option *ngFor="let technician of dropdownListData?.technicians"
                                                    value="{{technician.technicianId}}">
                                                    {{technician.technicianName}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                   <div class="polColRw">
                                        <input type="checkbox" name="" class="popCkBx">
                                        <label>Select Email Alert to Technician</label>
                                        <textarea class="popCmnt" cols="" formControlName="emailContent" rows="">Email comment required</textarea>
                                    </div>
                                    <!--  <div class="polColRw">
                                        <input type="checkbox" name="" class="popCkBx">
                                        <label>Attach Main photo</label>
                                    </div>
                                    <div class="polColRw">
                                        <input type="checkbox" name="" class="popCkBx">
                                        <label>Attach Action photo(s)</label>
                                    </div> -->
                                </div>
                            </form>
                        </div>
                        <fieldset class="col-md-6 pr-0" [disabled]="!isCreateJob">
                            <form [formGroup]="createJobForm">
                                <div class="popRgtcol">
                                    <div class="polColRw">
                                        <div class="popClsDv">
                                            <div class="polCl">
                                                <input type="text" name="jobname" formControlName="jobName" placeholder="Job Name">
                                            </div>
                                            <div class="polCl">
                                                <input type="number" name="jobnumber" formControlName="jobNumber" placeholder="Job Number">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="polColRw">
                                        <label>Select Job Start and End Dates</label>
                                        <!-- Range Picker -->
                                        <div class="polCl">
                                            <div class="SlctRow hayhack">
                                                <input type="text" formControlName="bsRangeValue" placeholder="Date Range" class="popCkBx"
                                                    bsDaterangepicker #dp="bsDaterangepicker" placement="bottom"
                                                    [minDate]="today" readonly />
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="polColRw">
                                        <label>Select Approval Type</label>
                                        <div class="polCl">
                                            <select>
                                                <option value="approval"> Approval</option>
                                            </select>
                                        </div>
                                    </div> -->
                                </div>
                            </form>
                        </fieldset>
                    </div>
                </div>

            </div>
    </div>

    <div class="modal-footer">
        <a href="javascript:void(0)" class="btnAt" (click)="onCancel()">
            <span>CANCEL</span></a>
        <button href="javascript:void(0)" type="submit" class="btnAt ng-star-inserted" (click)="submit()" [disabled]="(isCreateJob ? createJobForm.invalid : selectJobForm.invalid) || !customerId">
            <span ngcontent-prv-c400="" *ngIf="title === 'reassign'">REASSIGN</span>
            <span ngcontent-prv-c400="" *ngIf="title === 'create' && isCreateJob">CREATE AND ASSIGN</span>
            <span ngcontent-prv-c400="" *ngIf="title === 'create' && !isCreateJob">ASSIGN</span>
        </button>
    </div>


</div>