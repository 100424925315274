<div class="auth-page">
  <div class="auth-page__content-block">
    <div class="auth-page__content-wrapper">
      <mat-tab-group class="auth-page__group">
        <mat-tab label="Login">
          <h4 class="auth-page__group-title">CAMS Admin</h4>
          <div class="auth-page__google-button-wrapper">
            <!--<button mat-raised-button class="auth-page__google-button">
              <img class="auth-page__google-button-icon" src="./assets/auth/google.svg" alt="google">Sign in with Google
            </button>-->
          </div>

          <!--<div class="auth-page__border-wrapper">
            <div class="auth-page__border-line"></div>
            <p class="auth-page__border-title">or</p>
            <div class="auth-page__border-line"></div>
          </div>-->

          <app-login-form (sendLoginForm)="sendLoginForm()"></app-login-form>

        </mat-tab>
        <!--<mat-tab label="Forgot Pass">
          <h4 class="auth-page__group-title">Welcome!</h4>
          <p class="auth-page__group-sub-title">Please Provide your Email Address </p>

          <app-forget-pass ></app-forget-pass>

          <div class="auth-page__border-wrapper">
            <div class="auth-page__border-line"></div>
            <p class="auth-page__border-title">or</p>
            <div class="auth-page__border-line"></div>
          </div>

          <div class="auth-page__google-button-wrapper">
            <button mat-raised-button class="auth-page__google-button">
              <img class="auth-page__google-button-icon" src="./assets/auth/google.svg" alt="google">Sign in with Google
            </button>
          </div>
        </mat-tab>-->
      </mat-tab-group>

      <p class="auth-page__footer-title">© 2014-{{todayDate | year}} <a href="https://flatlogic.com/">CAMS-</a>,
        Companion Asset Management, LLC. All rights reserved.</p>
    </div>
  </div>
  <div class="auth-page__logo">
    <div class="auth-page__logo-wrapper">
      <div class="loginRgtLft">
        <div class="logoDv">
          <img class="auth-page__logo-img" src="./assets/images/cams-logo-black.png" alt="logo">
        </div>
        <h1>
          ATM<br>
          brand<br>
          intelligence
        </h1>
      </div>
      <div class="loginRgtRgt">
        <div class="loginImg">
          <img class="auth-page__logo-img" src="./assets/images/laptop.png" alt="logo">
        </div>
      </div>
      <!-- <h6 class="auth-page__logo-title">CAMS Admin</h6> -->
    </div>
  </div>
</div>