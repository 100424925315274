import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class SnackbarService {
  constructor(public _snackBar: MatSnackBar) {}

  notifySuccess(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: "green-snackbar",
    });
  }

  notifyError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: "red-snackbar",
    });
  }

  notifyWarning(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: "yellow-snackbar",
    });
  }
}
