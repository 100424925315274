import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { PieChartData } from '../models/pie-chart-data';

@Injectable({
  providedIn: 'root'
})
export class ChartsService {
  constructor() { }
  public loadPieChartData(params?:any): Observable<PieChartData> {
    return of({
      series: [params.KpiCount],
      colors : [params.ColorCode],
      labels: [params.IssueCategory]
    });
  }
}
