import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root"
})
export class HeaderService {
  constructor(private http: HttpClient) {}

  public get_linked_user_accounts(params):Observable<any>
  {
    return this.http.post(environment.baseURL + "/bank-list"+ environment.api_postfix,params,{});
  }
 
}
