<app-header id="dev_sidebar"></app-header>

<mat-sidenav-container class="layout">
  <mat-sidenav
    #sidenav
    mode="side"
    [(opened)]="isShowSidebar"
    class="layout-sidebar"
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    [fixedInViewport]="mobileQuery.matches"
   
  >
    <app-sidebar></app-sidebar>
    <div class="navAr" (click)="showSideBar() "><mat-icon>arrow_forward_ios</mat-icon></div>
  </mat-sidenav>
  <mat-sidenav-content class="layout-content">
    <ng-content></ng-content>
   
  </mat-sidenav-content>
 
</mat-sidenav-container>


