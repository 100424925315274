import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl ,Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AssetsService } from '../assetlink/services/assets.service';
import { environment } from 'src/environments/environment';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';


@Component({
  selector: 'app-sendassettoemail',
  templateUrl: './sendassettoemail.component.html',
  styleUrls: ['./sendassettoemail.component.css']
})
export class SendassettoemailComponent implements OnInit {
  public isLoading: boolean = false;
  public title;
  public data:any;
  public emailForm:FormGroup;
  public submitted=false;
  public baseUrl: string = environment.S3Url;

  constructor(public modalRef: BsModalRef, 
              private _formBuilder: FormBuilder,
              public service:AssetsService,
              private _snack$: SnackbarService
              ) { }

  ngOnInit(): void {

    this.emailForm = this._formBuilder.group({
      //email:   ['' ,Validators.required    ],
      email :new FormControl('',[Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      body: ['' ,Validators.required],
      subject:['' ,Validators.required]
    });
  }

  /**
   * On confirm send email
   */
  onConfirm(){

    if(!this.emailForm.valid){
      this.submitted=true;
      return ;
    }
    
    this.isLoading=true;
    let params={
      receiver:this.emailForm.controls.email.value,
      body_text:this.emailForm.controls.body.value,
      body_html:this.getHtmlOfEmail(),
      subject:this.emailForm.controls.subject.value,
      key:this.data.ImageURL,
    }

    this.isLoading = true;
    this.service.EmailAsset(params).subscribe({
      
      next: (response: any) => {
        console.log(response)
        this.modalRef.hide();
        this.isLoading=false;
       },
       error: (err: Error) => {
        this._snack$.notifyError('Something Went Wrong', 'OK');
         console.log(err)
         this.isLoading = false;
       },
       complete: () => {
         this.isLoading = false;
       },

    })
  }

  /**
   * 
   * generating html of the email
   */

  getHtmlOfEmail(){
    let institution_assigned_id=this.data.asset_profile.atm_information[0].institution_assigned_id;
    let atm_description = this.data.asset_profile.atm_information[0].atm_description;
    let comments = this.emailForm.controls.body.value;
    let image = this.data.ImageURL

    let str = 
    `<link href="https://fonts.googleapis.com/css2?family=Exo+2:wght@100;200;300;400;500;600;700;800;900&family=Signika:wght@300;500;700&display=swap" rel="stylesheet">
    <table border="0" cellspacing="0" cellpadding="0" width="100%">
      <tr>
        <td>
          <table border="0" cellspacing="0" cellpadding="0" width="100%">
            <tr>
              <td valign="middle" bgcolor="#ebecec" height="75px">
                <img src="http://44.197.139.189/assets/images/cams-logo-black.png" alt="logo" height="34" width="" style="margin-left:50px; margin-right: 50px;">
              </td>
            </tr>
          </table> 
    
          <table border="0" cellspacing="0" cellpadding="0" width="100%">
            <tr>
              <td valign="top">
                <p style="font-family: 'Signika', sans-serif; font-size: 20px; font-weight:500; color: #2c2e35; margin: 30px 50px 15px 50px; margin-left: 50px">Action Item Details: </p>
    
                <p style="font-family: 'Signika', sans-serif; font-size: 16px; font-weight:500; color: #2c2e35; margin:0 50px 15px 50px;">
                  Asset Information: `+institution_assigned_id+` 
                  <span style="color:#6f7176">
                   `+atm_description+`
                  </span> 
                </p>   
                
                <p style="font-family: 'Signika', sans-serif; font-size: 16px; font-weight:500; color: #2c2e35; margin: 0 50px 15px 50px;">Action Item Sent By: <a href="#" style="color: #00b9f2;">jcheslevOcams-axis.com</a> </p>
                <p style="font-family: 'Signika', sans-serif; font-size: 16px; font-weight:500; color: #2c2e35; margin: 0 50px 15px 50px;">Action Item Category:`+this.data.IssueType+`  </p>
                <p style="font-family: 'Signika', sans-serif; font-size: 16px; font-weight:500; color: #2c2e35; margin: 0 50px 15px 50px;">Action Item Subcategory: `+this.data.IssueSubType+`  </p>
                <p style="font-family: 'Signika', sans-serif; font-size: 16px; font-weight:500; color: #2c2e35; margin: 0 50px 15px 50px;">Action Item Description:`+this.data.IssueDescription+`   </p>
                <p style="font-family: 'Signika', sans-serif; font-size: 16px; font-weight:500; color: #2c2e35; margin: 0 50px 15px 50px;">Comments:</p>
    
                <p style="font-family: 'Signika', sans-serif; font-size: 16px; font-weight:500; color: #2c2e35; margin: 0 50px 15px 50px; border: 1px solid #ebecec; min-height: 96px; line-height: 50px;"> 
                &nbsp;&nbsp;&nbsp;&nbsp; `+comments+`
                </p>
    
                <p style="font-family: 'Signika', sans-serif; font-size: 16px; font-weight:500; color: #2c2e35; margin: 0 0 15px; margin-left: 50px">Images:</p>
                <p style="font-family: 'Signika', sans-serif; font-size: 16px; font-weight:500; color: #2c2e35; margin: 0 ; margin-left: 50px; margin-right: 50px;">
                  <img src=`+image+` alt="image" width="165" height="150" style="margin-right: 60px">
                </p>
              </td> 
            </tr>
          </table> 
          <table border="0" cellspacing="0" cellpadding="0" width="100%">
            <tr>
                <td>
                    <p style="font-family: 'Signika', sans-serif; font-size: 16px; font-weight:600; color: #2c2e35; margin: 40px 0 15px; margin-left: 50px">This automated email was sent by CAMS. For questions please contact your institution administrator or your sales representative or <a style="color:#57bff2; text-decoration: none;" href="mailto:support@camscompanion.com" >support@camscompanion.com.</a> </p>
                </td>
            </tr>
            <tr>
                <td bgcolor="#f9f9f9">
                    <table border="0" cellspacing="0" cellpadding="0" width="100%">
                        <tr>
                            <td height="20">&nbsp;</td>
                        </tr>
                    </table>
                    <table border="0" cellspacing="0" cellpadding="0" width="100%">
                        
                        <tr>
                            <td width="300" style="line-height: 0;">
                                <img src="http://44.197.139.189/assets/images/cams-logo-black.png" alt="logo" height="34" width="" style="margin-left:50px; margin-right: 50px;">
                                <p style="font-family: 'Signika', sans-serif;padding: 0px;font-size: 8px;color: #2c2e35;margin-left: 56px;margin-top:4px;">Companion Asset Management, LLC</p>
                            </td>
                            <td width="200">
                                <p style="font-family: 'Signika', sans-serif; font-size: 14px; font-weight:400; color: #2c2e35">533 W 2600 S Suite 120<br>
                                Bountiful. Utah 84010-7714<br>
                                (855) 735-2725</p>
                            </td>
                            <td width="210">
                                <a href="https://camscompanion.com/" target="_blank" style="font-family: 'Signika', sans-serif; font-size: 14px; font-weight:400; color: #57bff2; text-decoration:none;">www.camscompanion.com</a><br>
                                <a href="mailto:support@camscompanion.com" style="font-family: 'Signika', sans-serif; font-size: 14px; font-weight:400; color: #57bff2; text-decoration:none;">support@camscompanion.com</a><br>
                                <a href="mailto:info@camscompanion.com" style="font-family: 'Signika', sans-serif; font-size: 14px; font-weight:400; color: #57bff2; text-decoration:none;">info@camscompanion.com</a>
                            </td>
                            <td><img src="http://44.197.139.189/assets/images/ftr.png" alt="footer-image" style="margin-right: 50px"></td>
                        </tr>
                    
                        
                    </table>
                    <table border="0" cellspacing="0" cellpadding="0" width="100%">
                        <tr>
                            <td>
                                <p style="font-family: 'Signika', sans-serif; font-size: 16px; font-weight:400; color: #2c2e35; margin: 15px 0 0; margin-left: 50px; margin-right: 50px">
                                    The information in this e-mail is confidential and may be legally privileged. It is intended solely for the addressee. Access to this e-mail by anyone else is unauthorized. If you are not the intended recipient, any disclosure, copying, distribution or any action taken or omitted based on this information is prohibited and may be unlawful. If you are not the intended recipient, please reply to the sender that you received this e-mail in error and that you have deleted this e-mail and have destroyed any and all copies of this e-mail. 
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td height="20">&nbsp;</td>
                        </tr>
                    </table>    
                </td>
            </tr>
        </table> 
        </td>
      </tr>
    </table>`;
    return str;



  }

  /**
   * On pressing the cancel button hide the popup
   */

  onCancel(){
   // console.log("hello" ,this.data)
    this.modalRef.hide();
   let element =  <HTMLInputElement>document.getElementById('action_cls_'+this.data.index);
   element.value="";
  }




}
