export enum routes {
  //Dashboard Module
  DASHBOARD = 'dashboard',
  CUSTOMER = 'customer',
  TYPOGRAPHY = '/typography',
  TABLES = '/tables',
  NOTIFICATION = '/notification',
  UI_ELEMENTS_ICONS = '/ui/icons',
  UI_ELEMENTS_CHARTS = '/ui/charts',
  UI_ELEMENTS_MAP = '/ui/map',
  LOGIN = '/login'
}
