import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { DashboardService } from '../../services';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { debounceTime, tap, switchMap, finalize, distinctUntilChanged, filter } from 'rxjs/operators';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { SwitchUserService } from 'src/app/pages/switch-user/switch-user.service';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public isLoading: boolean = true;

  //displayedColumns: string[] = ['position', 'name', 'weight', 'symbol','zip'];
  displayedColumns: string[] = [
    'assetid',
    'atmdescription',
    'address',
    'city',
    'state',
    'zip',
    'status',
  ];
  jobyears: any;
  customerSeleted: any;
  statusSelected: any;
  yearSelected: any;
  monthSelected: any;
  jobNumberSelected: any;
  jobNameSelected: any;

  customerSelectedText: any;
  jobStatusSelectedText: any;
  jobYearSelectedText: any;
  jobMonthSelectedText: any;
  jobNumberSelectedText: any;
  jobNameSelectedText: any;

  dataSource: any;

  tableData: any;
  jobStatus: any;
  customer_list: any;
  job_name_number: any;
  counts: any;
  total_asset_count = 0;
  asset_in_job = 0;
  assigned = 0;
  field_complete = 0;
  approved = 0;
  cancelled_billable = 0;
  cancelled_non_billable = 0;
  showPie = 'false';

  revenueChartData$: any;
  dashboardForm: FormGroup;
  data: any;
  public no_data_message = "";
  params = {
    company_id: '',
    job_status_id: '',
    year: '',
    month: '',
    job_id: '',
  };

  isCustLoading = false;
  myControl = new FormControl('');
  customerList = [];
  rowPageIndex=0;

  constructor(
    private service: DashboardService,
    public formBuilder: FormBuilder,
  ) { }


  /**
   * On initialisation of the page certain methods are called 
   * to show the initial data of the page.
   */
  ngOnInit(): void {
    this.no_data_message = "All Customer Result";
    //loading the form group
    this.loadFormControl();
    this.getJobNumber(this.params);
    this.getCustomerList();
    this.get_years();   
    this.getJobStatus();
    this.myControl.valueChanges
    .pipe(
      debounceTime(300),
      tap(() =>{ this.customerList=[]; this.isCustLoading = true}),
      switchMap(value => this.service.getCustomerList({ searchkey: value,'role':localStorage.getItem('role'),'user_id':localStorage.getItem('user_id') })
        .pipe(
          finalize(() => this.isCustLoading = false),
        )
      )
    )
    .subscribe(response =>{this.isCustLoading = false; this.customerList = response.customer_list; });
    
  }


  
  getCustomerId(customerId: string) {
    const res = this.customerList.find((customer) => customer.CustomerId === customerId);
    if (res) {
      return res.CustomerName;
    }
  }

  /**
   * initialising the dashboard formm control
   */

  public loadFormControl = (): void => {
    this.dashboardForm = this.formBuilder.group({
      customer: [''],
      job_status: [''],
      job_year: [''],
      job_month: [''],
      job_nuumber: [''],
      job_name: [''],
    });
  };

  /**
   * return the result of admin filteration in admin panel dashboard
   */
  search(event: MatSelectChange, fieldname = '',customerId="") {

  
    this.showPie = 'false';
    this.no_data_message = "";
    if (fieldname == 'customer') {
      this.customerSelectedText = event.source.triggerValue; 
      console.warn(customerId)
      if (this.customerSeleted == "") {
        this.customerSelectedText = "";
      }

      this.params.company_id = "'" + customerId + "'";
      this.jobNumberSelected = '';
      this.params.job_id = "";
      this.dashboardForm.patchValue({
        job_status: '',
        job_year: '',
        job_month: '',
        job_nuumber: '',
        job_name: ''
      });
      
    }
    if (fieldname == 'job_status') {
      this.jobStatusSelectedText = event.source.triggerValue;
      this.params.job_status_id = <string>this.statusSelected;
    }
    if (fieldname == 'job_year') {
      this.jobYearSelectedText = event.source.triggerValue;
      this.params.year = this.yearSelected;
    }
    if (fieldname == 'job_month') {
      this.jobMonthSelectedText = event.source.triggerValue;
      this.params.month = this.monthSelected;
    }
    if (fieldname == 'job_nuumber') {
      this.jobNumberSelectedText = event.source.triggerValue;
      this.params.job_id = this.jobNumberSelected;
    }
    if (fieldname == 'job_name') {      
      this.jobNameSelectedText = event.source.triggerValue;
      this.params.job_id = this.jobNameSelected;
    }
      
    this.isLoading = true;
    this.getJobNumber(this.params)
    
  }

  getJobNumber(params,rowIndex?: string, rowItem?: string) {

    this.isLoading = true;
    params.RowIndex = this.rowPageIndex.toString();
    params.RowItem = rowItem ? rowItem.toString() : "5";
    params.role = localStorage.getItem('role');
    params.user_id = localStorage.getItem('user_id');
    this.service.job_name_number(params).subscribe({
      next: (response: any) => {
        if (response.code == 200) {
          this.job_name_number = response.data.job;
          this.dataSource = new MatTableDataSource<any>(response.data.asset_list);
          if (!rowIndex && !rowItem) {
            this.dataSource.paginator = this.paginator;
          }
          this.counts = response.data.counts;
          this.total_asset_count = this.counts.total_atm_count;
          this.asset_in_job = this.counts.count_in_jobs;
          this.assigned = this.counts.assigned;
          this.field_complete = this.counts.field_complete;
          this.approved = this.counts.approved;
          this.cancelled_billable = this.counts.cancelled_billable;
          this.cancelled_non_billable = this.counts.cancelled_non_billable;

          this.revenueChartData$ = {
            groupA: this.assigned,
            groupB: this.field_complete,
            groupC: this.approved,
            groupD: this.cancelled_billable,
            groupE: this.cancelled_non_billable,
          };
          this.showPie = 'true';
        } else {
          this.no_data_message = "No Data Found";
        }
      },
      error: (err: Error) => {
        console.error(err['error'].message);
      },
      complete: () => {
        this.isLoading = false;
        this.jobNumberSelected = '';
        this.params.job_id = "";
      },
    });
  }
  onPaginateChange(event) {
    this.rowPageIndex = event.pageIndex*5;
    
    this.getJobNumber(this.params,event.pageIndex, event.pageSize);
  }
  /**
   * generating the list of years from 2000
   */

  get_years() {
    this.jobyears = [];

    for (let i = 2000; i <= new Date().getFullYear(); i++) {
      this.jobyears.push(i);
    }
    return this.jobyears;
  }

  getCustomerList() {
    const params={
      'searchkey':'',
      "user_id": localStorage.getItem('user_id'),
      "role": localStorage.getItem('role')
    }
    this.service.load_customer_list(params).subscribe((response) => {
      this.customer_list = response.data.customer;
       //this.isLoading=false;
    });
  }

  getJobStatus(){
    this.service.getJobStatus().subscribe((response) => {
      this.jobStatus = response;
    });
  }

}
