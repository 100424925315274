import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AssetsService } from '../assetlink/services/assets.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
@Component({
  selector: 'app-ignoreitem',
  templateUrl: './ignoreitem.component.html',
  styleUrls: ['./ignoreitem.component.css']
})
export class IgnoreitemComponent implements OnInit {

  title;
  data:any;
  ignoreForm :FormGroup;
  public submitted=false;
  constructor(public modalRef: BsModalRef ,
     public service:AssetsService,
     private _formBuilder: FormBuilder,
     private _snack: SnackbarService) { }




  public generateForm = (): void => {
    this.ignoreForm = this._formBuilder.group({
      comment: ['' ,Validators.required],
    });
    //calling the assets list
    //this.listAllAssets();
    
  };

  ngOnInit(): void {
    //console.log("full>>",this.data);
    this.generateForm()
  }

  /**
   * 
   * on confirming in the popup remove the isseue from the list
   */

  public onConfirm() :void{
    if(!this.ignoreForm.valid){
      this.submitted=true;
      return ;
    }

   let params={
      AnswerIssueID:this.data.AnswerIssueId,
      UserName:this.data.username,
      IgnoreComment:this.ignoreForm.controls.comment.value


    }
    this.service.ignoreItems(params).subscribe(
      {
        next: (response: any) => {
         //console.log(response)
         this.modalRef.hide();
         //document.getElementById('remove_action_item_'+this.data.index).style.display = "None";
         document.getElementById('remove_action_item_'+this.data.index).remove();
         //this._snack.notifySuccess("Email Sent Successfully", 'Success');
        },
        error: (err: Error) => {
          this._snack.notifyError(err['error'].message, 'OK');
          console.log(err)
        },
        complete: () => {
          //this.isLoading = false;
        },
      }

      )

  }

  /**
   * on cancel just removes the popup
   */

  onCancel(){
    //console.log("hello" ,this.data)
    this.modalRef.hide();
   let element =  <HTMLInputElement>document.getElementById('action_cls_'+this.data.index);
   element.value="";
  }

}
