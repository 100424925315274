<div *ngIf="isLoading" style="
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    ">
        <mat-spinner color="primary"></mat-spinner>
    </div>

<div class="aprvlPop reassignMentPop">

    <div class="modal-header">
        <div _ngcontent-ole-c400="" class="aprvlPopLogo">
            <img src="./assets/images/cams-logo-black.png" alt="">
        </div>
        <div class="aprvlPopedtImgDv">{{headerTitle}}</div>
    </div>

    <div class="modal-body">
            <div class="Acol">
                <div class="popRw">
                    <div class="row">
                        <form [formGroup]="createJobForm">
                            <div class="polColRw">
                                <label>1. Select Customer
                                    <span class="required">*</span>
                                </label>
                                <div class="popClsDv">
                                    <div class="polCl" *ngIf="user_type === 'Customers'">
                                        <select #jobCustomerSelect formControlName="CustomerId">
                                            <option selected="true" disabled="disabled" value="">Customer</option>
                                            <option *ngFor="let customer of customer_list" value="{{customer.CustomerId}}"
                                                text="customer.CustomerName">
                                                {{customer.CustomerName}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="polCl"  *ngIf="user_type !== 'Customers'">
                                        <select #jobCustomerSelect formControlName="CustomerId">
                                            <option selected="true" disabled="disabled" value=""> Customer</option>
                                            <option *ngFor="let customer of customer_list" value="{{customer.CustomerId}}"
                                                text="customer.CustomerName">
                                                {{customer.CustomerName}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="polColRw">
                                <label>2. Create Job
                                    <span class="required">*</span>
                                </label>
                                <div class="popClsDv">
                                    <div class="polCl">
                                        <input type="text" name="jobname" formControlName="JobName" placeholder="Job Name">
                                    </div>
                                    <div class="polCl">
                                        <input type="text" name="jobnumber" formControlName="JobNumber" placeholder="Job Number">
                                    </div>
                                </div>
                            </div>
                            <div class="polColRw">
                                <label>3. Select Job Start and End Dates
                                    <span class="required">*</span>
                                </label>
                                <div class="popClsDv">
                                <!-- Range Picker -->
                                    <div class="polCl">
                                        <div class="SlctRow hayhack">{{bsRangeValue}}
                                            <input type="text" formControlName="bsRangeValue" placeholder="Date Range" class="popCkBx"
                                                bsDaterangepicker #dp="bsDaterangepicker" placement="bottom"
                                                 readonly />
                                        </div>
                                        <p class="required" *ngIf="!isDateValid">Start Date cannot be in the past.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="polColRw">
                                <label>4. Job Description
                                    <span class="required">*</span>
                                </label>
                                <div class="popClsDv">
                                <!-- Range Picker -->
                                    <div class="polCl">
                                        <div class="SlctRow hayhack">
                                            <textarea class="popCmnt" cols="" formControlName="JobDescription" rows="" placeholder="Job Description">Email comment required</textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div>
                            <span class="required">* Required Field</span>
                        </div>
                    </div>
                </div>

            </div>
    </div>

    <div class="modal-footer">
        <a href="javascript:void(0)" class="btnAt" (click)="onCancel()">
            <span>CANCEL</span></a>
        <button href="javascript:void(0)" type="submit" class="btnAt ng-star-inserted" (click)="submit()" [disabled]="createJobForm.invalid || !isDateValid">
            <span ngcontent-prv-c400="">{{buttonTitle}}</span>
        </button>
    </div>


</div>