import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CoreEnvironment } from '@angular/compiler/src/compiler_facade_interface';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpInterface } from 'src/app/shared/services/interfaces/http.interface';
import { environment } from 'src/environments/environment';
//import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';

const headers = new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Access-Control-Allow-Origin', '*');

@Injectable({
  providedIn: 'root',
})
export class ApprovalService {

  public S3accessKeyId = environment.S3accessKeyId;
  public S3secretAccessKey = environment.S3secretAccessKey;
  public S3region = environment.S3region;
  public S3BucketName = environment.S3BucketName;
  constructor(public http: HttpClient) {

  }

  public load_status_list(): Observable<any> {
    return this.http.post(environment.baseURL + "/admin-customer-dashboard" + environment.api_postfix, {});
  }

  public get_job_ids(params): Observable<any> {
    return this.http.post(environment.baseURL + "/admin-job-number-job-name" + environment.api_postfix, params, {});
  }

  public get_approval_tool_listing(params): Observable<any> {
    return this.http.post(environment.baseURL + "/approval-tool-listing" + environment.api_postfix, params, {});
  }

  public get_approval_tool_details(params): Observable<any> {
    return this.http.post(environment.baseURL + "/approval-tool-details" + environment.api_postfix, params, {});
  }

  public get_approval_tool_question_answer_update(params): Observable<any> {
   // return this.http.post(environment.baseURL + "/approval-tool-update" + environment.api_postfix, params, {});
   return this.http.post(environment.baseURL + "/approval-tool-update-new" + environment.api_postfix, params, {});
  }

  public approve_item(params): Observable<any> {
    return this.http.post(environment.baseURL + "/approve-survey" + environment.api_postfix, params, {});
  }


  public get_approval_tool_image_tag(params): Observable<any> {
    return this.http.post(environment.baseURL + "/" + "get-image-tags" + environment.api_postfix, params, {});
  }

  fileUpdate(file, currentupdateDetailsParam, imageData) {

    var Buffer = require('buffer/').Buffer;
    const bodydata = new Buffer(imageData.replace(/^data:image\/\w+;base64,/, ""), 'base64');

    var get_image_tag_params = {
      "status": false,
      "message": "Upload Failed"
    }
    var ext = file.name.substr(file.name.lastIndexOf('.') + 1);
    var full_file_name = currentupdateDetailsParam.image_details.image_name + '.' + ext;
    var bucketPath = this.S3BucketName + '/images/' + currentupdateDetailsParam.JobId + '/' + currentupdateDetailsParam.ATMID;
    const contentType = file.type;
    const bucket = new S3({
      accessKeyId: this.S3accessKeyId,
      secretAccessKey: this.S3secretAccessKey,
      region: this.S3region,
    });
    const params = {
      Bucket: bucketPath,
      Key: full_file_name,
      Body: bodydata,
      ACL: 'public-read',
      ContentType: contentType,
      ContentEncoding: 'base64'
    };
    bucket.putObject(params, function (err, data) {
      if (err) {
        console.log('EROOR: ', JSON.stringify(err));
        return get_image_tag_params;
      }
      var get_image_tag_params = {
        "status": true,
        "message": "Upload Success",
        "image_name": currentupdateDetailsParam.image_details.image_name,
        "ext": ext
      }
      console.log('File Uploaded.', data);
      return get_image_tag_params;
    });

  }

  public load_customer_list(params): Observable<any> {
    return this.http.post(environment.baseURL + "/dashboard-customer-list" + environment.api_postfix, params, {});
  }

  base64_encode(Bucket, Image_name) {
    var Buffer = require('buffer/').Buffer;
    const bucket = new S3({
      accessKeyId: this.S3accessKeyId,
      secretAccessKey: this.S3secretAccessKey,
      region: this.S3region,
    });
    var params = {
      'Bucket': Bucket, // your bucket name,
      'Key': Image_name
    }
    return bucket.getObject(params).promise();
  }

  fileUpdatewithRawBase64(currentupdateDetailsParam, imageData) {
    var Buffer = require('buffer/').Buffer;
    const bodydata = new Buffer(imageData.replace(/^data:image\/\w+;base64,/, ""), 'base64');

    var get_image_tag_params = {
      "status": false,
      "message": "Upload Failed"
    }
    var ext = "png";
    var full_file_name = currentupdateDetailsParam.ImageId + '.' + ext;
    var bucketPath = this.S3BucketName + '/images/' + currentupdateDetailsParam.JobId + '/' + currentupdateDetailsParam.ATMID;
    const contentType = "image/png";
    const bucket = new S3({
      accessKeyId: this.S3accessKeyId,
      secretAccessKey: this.S3secretAccessKey,
      region: this.S3region,
    });
    const params = {
      Bucket: bucketPath,
      Key: full_file_name,
      Body: bodydata,
      ACL: 'public-read',
      ContentType: contentType,
      ContentEncoding: 'base64'
    };
    bucket.putObject(params, function (err, data) {
      if (err) {
        console.log('EROOR: ', JSON.stringify(err));
        return get_image_tag_params;
      }
      var get_image_tag_params = {
        "status": true,
        "message": "Upload Success",
        "image_name": currentupdateDetailsParam.image_details.image_name,
        "ext": ext
      }
      console.log('File Uploaded.', data);
      return get_image_tag_params;
    });

  }


  public uploadImage(params): Observable<any> {
    return this.http.post(environment.baseURL + "/multi-image-upload" + environment.api_postfix, params, {});
  }

  public editImage(params): Observable<any> {
    return this.http.post(environment.baseURL + "/edit-image" + environment.api_postfix, params, {});
  }
 


}
