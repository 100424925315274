export const environment = {
  production: false,
  qa: true,
  dev: false,
  baseURL: 'https://lacefdbx11.execute-api.us-east-1.amazonaws.com/qa',
  S3Url: 'https://s3.us-east-1.amazonaws.com/cams2021/',
  S3BucketName: 'cams2021',
  S3accessKeyId: 'AKIAS2SOJLXHN6DFLMMI',
  S3secretAccessKey: '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',
  S3region: 'us-east-1',
  api_postfix : '-qa'
};
