import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Component, OnInit, OnDestroy, Renderer2, ElementRef, ViewChild, Compiler, } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ApprovalService } from './services/approval.services';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { Observable } from 'rxjs';
import { saveAs } from 'file-saver';
import { DialogSingleImage } from '../assetlink/components/assetlink/assetlink.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import Swal from 'sweetalert2';
export interface ats {
  idn: string;
  desp: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  date: string;
  services: string;
  ServiceInstanceId: string;
}

@Component({
  selector: 'app-approval-tool',
  templateUrl: './approval-tool.component.html',
  styleUrls: ['./approval-tool.component.css']
})
export class ApprovalToolComponent implements OnInit, OnDestroy {
  isFirstOpen = true;
  slideConfig = { "slidesToShow": 4, "slidesToScroll": 1 };

  //-- Img Cropper start
  @ViewChild('myInput') myInputVariable!: ElementRef;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  tanNotFound = false;
  downloadBtnStatus = false;
  tagUpdateloader = false;
  tagUpdateBtnclass = '';
  donloadImageurl = '';
  donloadImagename = '';
  public imageFullURL: string = '';
  answerTypeValueId = [];
  answerTypeImages = []
  private base64textString: String = ""
  isImageUpload = false;

  publicUrl = '';
  setTimeout: any;

  //-- Img Cropper end
  randNumber = new Date().getTime();
  updateLoader = false;
  toFile;
  roteteDeg = 0;
  Image_rotete_status = 'disabled-btn';
  UploadBtnText = "";
  imageTagList = [];
  SelectedTag_name = '';
  Image_upload_btn_status = 'disabled-btn';
  Image_update_btn_status = 'disabled-btn';
  Base64_Image_update_btn_status = '';
  public baseUrl: string = environment.S3Url;
  public S3BucketName: string = environment.S3BucketName;
  totalrecordFound = 0;
  approval: ats[] = [];
  public customerId = '';
  public customerLookup: any;
  isLoading = true;
  public jobStatusId = '';
  public jobStatusLookup: any;
  public jobYear = '';
  public jobYearLookup: any;
  public jobMonth = '';
  public jobIdLookup: any;
  //public jobId = '';
  public jobNumber = '';
  public getJobIdsParams = {
    "company_id": '',
    "job_status_id": '',
    "year": '',
    'month': '',
    'job_id': '',
    'customerid': '',
    'job_number': '',
    'role': '',
    'user_id': ''
  };
  public get_image_tag_params = {
    "answer_type_value_id": ""
  }
  public getJobDetailsParams = {
    "serviceinstanceid": '',
    "customerid": ''
  };
  ServiceInstanceId = '';
  jobDetailsBasic = {
    "idn": "",
    "desp": "",
    "address": "",
    "city": "",
    "state": "",
    "zip": "",
    "date": "",
    "services": "",
    "ServiceInstanceId": ""
  }
  sliderImage: any[];
  IgnoreDetails = {
    "slider_images": [],
    "image_thumb_list": [],
    "summary_list": [],
    "technicianDetails": {

      "FieldComplete": "",
      "HostName": "",
      "UserName": "",
      "Email": "",
      "JobId": "",
      "JobName": "",
      "JobNumber": ""
    },
  }
  updateDetailsParam = {
    "answer": [],
    // "answer_type_value_id": "",
    "job_id": "",
    "atm_id": "",
    "service_instance_id": "",
    // "service_instance_answer_id": "",
    "question_id": "",
    "comment": "",
    "image_id": "",
    "image_ext": "",
    "image_tag": "",
    "images": []
  }

  currentupdateDetailsParam: any;
  selectedData = [];
  ApproveParam = {
    "service_instance_id": ""
  }
  sortedData: ats[];
  http: any;

  imageBase64 = '';
  imagebase64Updated = 'hidden_class';
  imagebase64NotShow = '';
  isViewOnly: any = false;
  showAnswer;
  showComment: any;
  saveAnswerBtn: boolean = false;

  rotateClicked = 1;
  actionPerformed = false;

  constructor(
    public dialog: MatDialog,
    private service: ApprovalService,
    private modalService: NgbModal,
    private renderer: Renderer2,
    private _compiler: Compiler,
    private _snack$: SnackbarService
  ) {
    this.sortedData = this.approval.slice();


  }


  ngOnInit(): void {
    this.customerId = localStorage.getItem('customer_id');
    const params = {
      'searchkey': '',
      "user_id": localStorage.getItem('user_id'),
      "role": localStorage.getItem('role')
    }
    this.service.load_customer_list(params).subscribe((response) => {
      this.customerLookup = response.data.customer;
      this.jobStatusLookup = response.data.job_status;
    });


    this.get_years();
    this.getJobIds();
    this.getApprovalToolListing();
    this.isViewOnly = (localStorage.getItem('role') == 'CV') ? true : false;
  }


  public nonClickTabIndex = 1;
  public() {
    const tabCount = 3;
    this.nonClickTabIndex = (this.nonClickTabIndex + 1) % tabCount;
  }

  filterByDataCollect(dataValue: any, dataType: any) {
    this.isLoading = true;
    if (dataType == 'job_status') {
      this.jobStatusId = dataValue;
      //this.getJobIds();
    }
    else if (dataType == 'job_year') {
      this.jobYear = dataValue;
      //this.getJobIds();
    }
    else if (dataType == 'job_month') {
      this.jobMonth = dataValue;
      //this.getJobIds();
    }
    else if (dataType == 'job_number') {
      this.jobNumber = dataValue;
      //this.getJobIds();
    }
    else if (dataType == 'job_customer') {
      if (!dataValue) {
        this.jobNumber = '';
      }
      this.customerId = dataValue;
      this.getJobIds();
    }
    else {
      // do nothing
    }

    this.getApprovalToolListing();

  }

  getJobIds() {
    this.jobIdLookup = [];
    this.getJobIdsParams.company_id = this.customerId;
    this.getJobIdsParams.job_status_id = this.jobStatusId;
    this.getJobIdsParams.year = this.jobYear;
    this.getJobIdsParams.month = this.jobMonth;
    this.service.get_job_ids(this.getJobIdsParams).subscribe({
      next: (response: any) => {
        if (response.code == 200) {
          this.jobIdLookup = response.data.job;
        }
      },
      error: (err: Error) => {
        console.error(err['error'].message);
      },
      complete: () => {
        //this.isLoading = false;
      },
    });

  }


  getApprovalToolListing() {
    this.isLoading = true;
    this.getJobIdsParams.company_id = this.customerId;
    this.getJobIdsParams.customerid = this.customerId;
    this.getJobIdsParams.job_status_id = this.jobStatusId;
    this.getJobIdsParams.year = this.jobYear;
    this.getJobIdsParams.month = this.jobMonth;
    //this.getJobIdsParams.job_id = this.jobId;
    this.getJobIdsParams.job_number = this.jobNumber;
    this.getJobIdsParams.role = localStorage.getItem('role');
    this.getJobIdsParams.user_id = localStorage.getItem('user_id');

    this.service.get_approval_tool_listing(this.getJobIdsParams).subscribe({

      next: (response: any) => {
        var rowData: ats[] = [];
        this.totalrecordFound = response.length;
        response.forEach(function (responseValue) {
          rowData.push({ idn: responseValue.InstitutionAssignedID, desp: responseValue.LocationDescription, address: responseValue.Address1, city: responseValue.City, state: responseValue.StateCode, zip: responseValue.PostalCode, date: responseValue.FieldComplete, services: responseValue.ServiceName, ServiceInstanceId: responseValue.ServiceInstanceId });
        });

        this.approval = rowData;
        this.sortData({ active: 'idn', direction: 'asc' });
      },
      error: (err: Error) => {
        console.error(err['error'].message);
      },
      complete: () => {
        this.isLoading = false;
      },
    });

  }

  approve_item(service_instance_id) {

    if (confirm("Are you sure want to approve this item?")) {
      this.isLoading = true;
      this.ApproveParam.service_instance_id = service_instance_id.toString();
      this.service.approve_item(this.ApproveParam).subscribe({

        next: (response: any) => {
          this.getApprovalToolListing();
          this.nonClickTabIndex = 1;
          this.isLoading = false;
        },
        error: (err: Error) => {
          console.error(err['error'].message);
        },
        complete: () => {
          //this.isLoading = false;
        },
      });
    }

  }

  sortData(sort: Sort) {
    const data = this.approval.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'idn':
          return compare(a.idn, b.idn, isAsc);
        case 'desp':
          return compare(a.desp, b.desp, isAsc);
        case 'address':
          return compare(a.address, b.address, isAsc);
        case 'city':
          return compare(a.city, b.city, isAsc);
        case 'state':
          return compare(a.state, b.state, isAsc);
        case 'zip':
          return compare(a.zip, b.zip, isAsc);
        case 'date':
          return compare(a.date, b.date, isAsc);
        case 'services':
          return compare(a.services, b.services, isAsc);
        default:
          return 0;
      }
    });
  }

  get_years() {
    this.jobYearLookup = [];

    for (let i = 2000; i <= new Date().getFullYear(); i++) {
      this.jobYearLookup.push(i);
    }
    return this.jobYearLookup;
  }

  openApprovalDetails(jobDetails) {

    //Store The jobeDetails
    localStorage.setItem('jobDetails', JSON.stringify(jobDetails));


    this.isLoading = true;
    this.ServiceInstanceId = jobDetails.ServiceInstanceId;
    this.getJobDetailsParams.serviceinstanceid = this.ServiceInstanceId.toString();
    this.getJobDetailsParams.customerid = this.customerId;

    this.service.get_approval_tool_details(this.getJobDetailsParams).subscribe({

      next: (response: any) => {
        if (response.result.summary_list.length) {
          this.jobDetailsBasic = jobDetails;
          this.IgnoreDetails.slider_images = Object.values(response.slider_images);
          let imgSlider = this.IgnoreDetails.slider_images
          console.log(this.IgnoreDetails.slider_images);
          //console.log(Object.values(this.IgnoreDetails.slider_images));
          const totalImageCount = (imgSlider) ? imgSlider.length : 0;
          if (totalImageCount) {
            const perChunk = 6 // items per chunk    
            const inputArray = imgSlider
            this.IgnoreDetails.image_thumb_list = inputArray.reduce((resultArray, item, index) => {
              const chunkIndex = Math.floor(index / perChunk)
              if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [] // start a new chunk
              }
              resultArray[chunkIndex].push({
                id: index,
                item: item
              })
              return resultArray
            }, []);
          }
          this.nonClickTabIndex = 2;
          var fieldCompleteDate='N/A';
          this.IgnoreDetails.technicianDetails = response.technician;
          var d = response.technician.FieldComplete;
          var arr=d.split(' ')
          var dt=arr[0].split('-');
          if(dt && dt[2]!='0000'){
            fieldCompleteDate=dt[1]+'/'+dt[0]+'/'+dt[2]+ " "+arr[1]
          }
          this.IgnoreDetails.technicianDetails.FieldComplete = fieldCompleteDate;
          this.isLoading = false;
          this.IgnoreDetails.summary_list = response.result.summary_list;
        }
        else {
          this._snack$.notifyError(response.message, 'OK');
        }

      },
      error: (err: Error) => {
        console.error(err['error'].message);
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }


  openApprovalDetailsInternal(jobDetails) {
    this.randNumber = new Date().getTime();
    this.ServiceInstanceId = jobDetails.ServiceInstanceId;
    this.getJobDetailsParams.serviceinstanceid = this.ServiceInstanceId.toString();
    this.getJobDetailsParams.customerid = this.customerId;
    this.isLoading = true;
    this.service.get_approval_tool_details(this.getJobDetailsParams).subscribe({

      next: (response: any) => {

        this.jobDetailsBasic = jobDetails;
        this.IgnoreDetails.slider_images = Object.values(response.slider_images);
        let imgSlider = this.IgnoreDetails.slider_images
        const totalImageCount = (imgSlider) ? imgSlider.length : 0;
        if (totalImageCount) {
          const perChunk = 6 // items per chunk    
          const inputArray = imgSlider
          this.IgnoreDetails.image_thumb_list = inputArray.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / perChunk)
            if (!resultArray[chunkIndex]) {
              resultArray[chunkIndex] = [] // start a new chunk
            }
            resultArray[chunkIndex].push({
              id: index,
              item: item
            })
            return resultArray
          }, []);
        }
        this.nonClickTabIndex = 2;
        var fieldCompleteDate='N/A';
        this.IgnoreDetails.technicianDetails = response.technician;
        var d = response.technician.FieldComplete;
        var arr=d.split(' ')
        var dt=arr[0].split('-');
        if(dt && dt[2]!='0000'){
          fieldCompleteDate=dt[1]+'/'+dt[0]+'/'+dt[2]+ " "+arr[1]
        }
        this.IgnoreDetails.technicianDetails.FieldComplete = fieldCompleteDate;

        this.IgnoreDetails.summary_list = response.result.summary_list;
        this.isLoading = false;



      },
      error: (err: Error) => {
        this._snack$.notifyError(err['error'].message, 'Error');
        console.error(err['error'].message);
        this.isLoading = true;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  public showSliderThumb(id) {

    let rem = document.getElementsByClassName('remove-selection-thumb-slider');
    for (let i = 0; i < rem.length; i++) {
      rem[i].classList.remove("thmb")
    }
    let element = document.getElementById("carousel-selector-" + id);
    element.classList.add("thmb");

  }

  public onItemChange(inputType, RowData, SummeryData, textBox, selectedBox) {
    // let postToApi = false;
    let summery_data_answer_type_value_id_arr = SummeryData.answer_type_value_id.split(',');

    this.updateDetailsParam = {
      "answer": [],
      //"answer_type_value_id": "",
      "job_id": "",
      "atm_id": "",
      "service_instance_id": "",
      // "service_instance_answer_id": "",
      "question_id": "",
      "comment": "",
      "image_id": "",
      "image_ext": "",
      "image_tag": "",
      "images": []
    }

    // var ext = SummeryData.Extension;
    // var image_name = SummeryData.ImageId;
    // var tag_name = SummeryData.TagName;

    // this.updateDetailsParam.image_id = image_name.toString();
    // this.updateDetailsParam.image_ext = ext.toString();
    // this.updateDetailsParam.image_tag = tag_name.toString();

    // if (inputType == 'yes/no') {
    //   var answer_type_value_id = $("#yes_no_" + SummeryData.QuestionId + " option:selected").val().toString();
    //   var comment = $("#comments_" + SummeryData.QuestionId).val().toString();
    //   this.updateDetailsParam.answer_type_value_id = answer_type_value_id.toString();
    //   this.updateDetailsParam.job_id = SummeryData.JobId.toString();
    //   this.updateDetailsParam.atm_id = SummeryData.ATMID.toString();
    //   this.updateDetailsParam.service_instance_id = SummeryData.ServiceInstanceId.toString();
    //   this.updateDetailsParam.question_id = SummeryData.QuestionId.toString();
    //   this.updateDetailsParam.service_instance_answer_id = SummeryData.ServiceInstanceAnswerId.toString();
    //   this.updateDetailsParam.comment = comment.toString();
    //   if (!summery_data_answer_type_value_id_arr.includes(answer_type_value_id) || SummeryData.Comments != comment) {
    //     postToApi = true;
    //   }

    // }
    // else 
    if (inputType == 'radio') {

      var answer_type_value_id = $('input[name="ansOptRadio_' + SummeryData.QuestionId + '"]:checked').val().toString();
      //var comment = $("#comments_" + SummeryData.QuestionId).val().toString();
      this.updateDetailsParam.answer.push({ "answer_type_value_id": answer_type_value_id.toString(), "ServiceInstanceAnswerId": SummeryData.ServiceInstanceAnswerId.toString() });
      //this.updateDetailsParam.answer_type_value_id = answer_type_value_id.toString();
      this.updateDetailsParam.job_id = SummeryData.JobId.toString();
      this.updateDetailsParam.atm_id = SummeryData.ATMID.toString();
      this.updateDetailsParam.service_instance_id = SummeryData.ServiceInstanceId.toString();
      this.updateDetailsParam.service_instance_id = SummeryData.ServiceInstanceId.toString();
      this.updateDetailsParam.question_id = SummeryData.QuestionId.toString();
      //this.updateDetailsParam.service_instance_answer_id = SummeryData.ServiceInstanceAnswerId.toString();
      //this.updateDetailsParam.comment = 'comment.toString()';
      this.updateDetailsParam.comment = '';
      // if (!summery_data_answer_type_value_id_arr.includes(answer_type_value_id) || SummeryData.Comments != comment) {
      //   postToApi = true;
      // }
    }
    // else if (inputType == 'checkbox') {
    //   var selectedValues = [];
    //   $("#MultiSelect_" + SummeryData.QuestionId + " :selected").each(function () {
    //     selectedValues.push($(this).val());
    //   });
    //   this.updateDetailsParam.answer_type_value_id = selectedValues.join(", ").toString();
    //   var comment = $("#comments_" + SummeryData.QuestionId).val().toString();
    //   this.updateDetailsParam.job_id = SummeryData.JobId.toString();
    //   this.updateDetailsParam.atm_id = SummeryData.ATMID.toString();
    //   this.updateDetailsParam.service_instance_id = SummeryData.ServiceInstanceId.toString();
    //   this.updateDetailsParam.service_instance_id = SummeryData.ServiceInstanceId.toString();
    //   this.updateDetailsParam.question_id = SummeryData.QuestionId.toString();
    //   this.updateDetailsParam.service_instance_answer_id = SummeryData.ServiceInstanceAnswerId.toString();
    //   this.updateDetailsParam.comment = comment.toString();
    //   if (!summery_data_answer_type_value_id_arr.includes(answer_type_value_id) || SummeryData.Comments != comment) {
    //     postToApi = true;
    //   }
    // }
    // else if (inputType == 'text') {
    //   this.updateDetailsParam.answer_type_value_id = SummeryData.answer_type_value_id.toString();
    //   var comment = $("#comments_" + SummeryData.QuestionId).val().toString();
    //   this.updateDetailsParam.job_id = SummeryData.JobId.toString();
    //   this.updateDetailsParam.atm_id = SummeryData.ATMID.toString();
    //   this.updateDetailsParam.service_instance_id = SummeryData.ServiceInstanceId.toString();
    //   this.updateDetailsParam.service_instance_id = SummeryData.ServiceInstanceId.toString();
    //   this.updateDetailsParam.question_id = SummeryData.QuestionId.toString();
    //   this.updateDetailsParam.service_instance_answer_id = SummeryData.ServiceInstanceAnswerId.toString();
    //   this.updateDetailsParam.comment = comment.toString();
    //   if (!summery_data_answer_type_value_id_arr.includes(answer_type_value_id) || SummeryData.Comments != comment) {
    //     postToApi = true;
    //   }
    // }

    // if (postToApi) {
    //   this.service.get_approval_tool_question_answer_update(this.updateDetailsParam).subscribe({

    //     next: (response: any) => {
    //       this.openApprovalDetailsInternal(SummeryData);
    //     },
    //     error: (err: Error) => {
    //       console.error(err['error'].message);
    //     },
    //     complete: () => {
    //       //this.isLoading = false;
    //     },
    //   });
    // }
    // console.log('updated param')
    // console.log(this.updateDetailsParam)

  }

  public checkSelected(optionData, stringData) {
    var arrayFromString = stringData.split(',');
    if (arrayFromString.indexOf(optionData.toString()) >= 0) {
      return true;
    }
    else {
      return false;
    }

  }

  open(content, backDropOption: any = '') {
    if (backDropOption) {
      this.modalService.open(content, backDropOption);
    } else {
      this.modalService.open(content);
    }

  }
  close() {
    this.modalService.dismissAll();
  }

  closepopup(closetype: any = '') {

    if (closetype == 'multi-image') {
      let jobDetails = JSON.parse(localStorage.getItem('jobDetails'));
      this.openApprovalDetailsInternal(jobDetails);
      this.modalService.dismissAll();
    } else {
      this.modalService.dismissAll();
      this.clear();
    }

  }

  ContinueWithImageTag() {
    $('#tag_div').hide();
    $('#img_upload_div').show();
  }

  ImagesubmitBase64Data(content) {
    this.Base64_Image_update_btn_status = 'disabled-btn';

    this.Image_update_btn_status = 'disabled-btn';
    this.Image_upload_btn_status = 'disabled-btn';
    this.Image_rotete_status = 'disabled-btn';
    this.updateLoader = true;
    this.service.fileUpdatewithRawBase64(this.currentupdateDetailsParam, this.imageBase64);
    var ext = "png";
    var image_name = this.currentupdateDetailsParam.ImageId;
    var updateImageTAG = $("#updateImageTAG option:selected").val();
    this.updateDetailsParam = {
      "answer": [],
      // "answer_type_value_id": "",
      "job_id": "",
      "atm_id": "",
      "service_instance_id": "",
      //"service_instance_answer_id": "",
      "question_id": "",
      "comment": "",
      "image_id": "",
      "image_ext": "",
      "image_tag": "",
      "images": []
    }

    this.updateDetailsParam.answer.push({ "answer_type_value_id": this.currentupdateDetailsParam.answer_type_value_id.toString(), "ServiceInstanceAnswerId": this.currentupdateDetailsParam.ServiceInstanceAnswerId.toString() });
    // this.updateDetailsParam.answer_type_value_id = this.currentupdateDetailsParam.answer_type_value_id.toString();
    this.updateDetailsParam.job_id = this.currentupdateDetailsParam.JobId.toString();
    this.updateDetailsParam.atm_id = this.currentupdateDetailsParam.ATMID.toString();
    this.updateDetailsParam.service_instance_id = this.currentupdateDetailsParam.ServiceInstanceId.toString();
    this.updateDetailsParam.question_id = this.currentupdateDetailsParam.QuestionId.toString();
    // this.updateDetailsParam.service_instance_answer_id = this.currentupdateDetailsParam.ServiceInstanceAnswerId.toString();
    if (this.currentupdateDetailsParam.Comments == null) {
      this.updateDetailsParam.comment = '';
    }
    else {
      this.updateDetailsParam.comment = this.currentupdateDetailsParam.Comments.toString();
    }

    this.updateDetailsParam.image_id = image_name.toString();
    this.updateDetailsParam.image_ext = ext.toString();
    this.updateDetailsParam.image_tag = updateImageTAG.toString();

    this.service.get_approval_tool_question_answer_update(this.updateDetailsParam).subscribe({

      next: (response: any) => {
        this._compiler.clearCache();
        this.updateLoader = false;
        this.croppedImage = '';
        $("#list_image_" + this.currentupdateDetailsParam.QuestionId).attr("src", "./assets/images/image-loader.gif");
        this.randNumber = new Date().getTime();
        this.close();
        this.isLoading = true;
        this.openApprovalDetailsInternal(this.currentupdateDetailsParam);
        this.downloadBtnStatus = false;
        this.Base64_Image_update_btn_status = '';
        //this.clear();

      },
      error: (err: Error) => {
        console.error(err['error'].message);
      },
      complete: () => {
      },
    });


  }


  Imagesubmit(content) {
    this.Image_update_btn_status = 'disabled-btn';
    this.Image_upload_btn_status = 'disabled-btn';
    this.Image_rotete_status = 'disabled-btn';
    this.updateLoader = true;
    const file = this.toFile.item(0);
    var imagesbase64 = $('#myImage').prop('src');
    this.service.fileUpdate(file, this.currentupdateDetailsParam, imagesbase64);
    var ext = file.name.substr(file.name.lastIndexOf('.') + 1);
    var image_name = this.currentupdateDetailsParam.image_details.image_name;
    var updateImageTAG = $("#updateImageTAG option:selected").val();
    this.updateDetailsParam = {
      "answer": [],
      //"answer_type_value_id": "",
      "job_id": "",
      "atm_id": "",
      "service_instance_id": "",
      // "service_instance_answer_id": "",
      "question_id": "",
      "comment": "",
      "image_id": "",
      "image_ext": "",
      "image_tag": "",
      "images": []

    }

    this.updateDetailsParam.answer.push({ "answer_type_value_id": this.currentupdateDetailsParam.answer_type_value_id.toString(), "ServiceInstanceAnswerId": this.currentupdateDetailsParam.ServiceInstanceAnswerId.toString() });
    //this.updateDetailsParam.answer_type_value_id = this.currentupdateDetailsParam.answer_type_value_id.toString();
    this.updateDetailsParam.job_id = this.currentupdateDetailsParam.JobId.toString();
    this.updateDetailsParam.atm_id = this.currentupdateDetailsParam.ATMID.toString();
    this.updateDetailsParam.service_instance_id = this.currentupdateDetailsParam.ServiceInstanceId.toString();
    this.updateDetailsParam.question_id = this.currentupdateDetailsParam.QuestionId.toString();
    //this.updateDetailsParam.service_instance_answer_id = this.currentupdateDetailsParam.ServiceInstanceAnswerId.toString();
    if (this.currentupdateDetailsParam.Comments == null) {
      this.updateDetailsParam.comment = '';
    }
    else {
      this.updateDetailsParam.comment = this.currentupdateDetailsParam.Comments.toString();
    }

    this.updateDetailsParam.image_id = image_name.toString();
    this.updateDetailsParam.image_ext = ext.toString();
    this.updateDetailsParam.image_tag = updateImageTAG.toString();

    this.service.get_approval_tool_question_answer_update(this.updateDetailsParam).subscribe({

      next: (response: any) => {
        this.updateLoader = false;
        this.croppedImage = '';
        $("#list_image_" + this.currentupdateDetailsParam.QuestionId).attr("src", "./assets/images/image-loader.gif");
        $('my_file').val('');
        this.close();
        this.isLoading = true;
        this.openApprovalDetailsInternal(this.currentupdateDetailsParam);
        this.downloadBtnStatus = false;
        this.clear();

      },
      error: (err: Error) => {
        console.error(err['error'].message);
      },
      complete: () => {
      },
    });

  }

  ImageonChange() {
    $('#img_upload_div').hide();
    $('#tag_div').show();

  }

  downloadImage(url, file_name) {
    const fileUrl = url;
    const fileName = file_name;
    saveAs(fileUrl, fileName);
  }



  selectImgFile() {
    document.getElementById('my_file').click();

  }





  async existing_rotate_left() {

    try {

      this.updateLoader = true;
      let foundData = await this.rotateImageLeft90Degree(this.imageBase64);
      this.imageBase64 = foundData.toString();
      this.imagebase64Updated = '';
      this.imagebase64NotShow = 'hidden_class';
      this.updateLoader = false;

    } catch (error) {
      this._snack$.notifyError(error, 'Error');

    }

  }

  async existing_rotate_right() {
    try {
      this.updateLoader = true;
      //let base64_encode_str = await this.getBase64FromUrl(this.imageBase64);
      //alert(base64_encode_str)
      let foundData = await this.rotateBase64Image90Degree(this.imageBase64);
      this.imageBase64 = foundData.toString();
      this.imagebase64Updated = '';
      this.imagebase64NotShow = 'hidden_class';
      this.updateLoader = false;
    } catch (error) {
      this._snack$.notifyError(error, 'Error');
      //alert(error)
    }
  }


  /**
   * 
   * @param event 
   * this function accepet an 
   * image when when the user click on replace
   * and select an image.
   * This happens when edit image button is 
   * clicked
   * 
   */
  async fileChangeEvent(event: any) {

    let imageFile = event.target.files[0];
    try {
      this.updateLoader = true;
      let foundData = await this.uploadTocanvas(imageFile);
      this.imageBase64 = foundData.toString();
      this.imagebase64Updated = '';
      this.imagebase64NotShow = 'hidden_class';
      this.updateLoader = false;
    } catch (error) {
      this._snack$.notifyError(error, 'Error');
    }

  }


  /**
   * 
   * @param imageFile 
   * @returns 
   * 
   * This Function upload a
   * new Image and display on canvas
   * when edit function is clicked
   * 
   */

  uploadTocanvas(imageFile) {
    return new Promise((resolve, reject) => {
      try {
        this.actionPerformed = true;
        let upload_imageBase64 = '';
        let reader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.onloadend = function (e) {
          let myImage = new Image(); // Creates image object
          myImage.src = <string>e.target.result; // Assigns converted image to image object
          myImage.onload = function (ev) {
            let myCanvas = <HTMLCanvasElement>document.getElementById("c"); // Creates a canvas object
            let myContext = myCanvas.getContext("2d"); // Creates a contect object
            myCanvas.width = myImage.width; // Assigns image's width to canvas
            myCanvas.height = myImage.height; // Assigns image's height to canvas
            myContext.drawImage(myImage, 0, 0); // Draws the image on canvas
            //upload_imageBase64 = myCanvas.toDataURL().toString();
            upload_imageBase64 = myCanvas.toDataURL("image/jpeg", 0.75); // Assigns image base64 string in jpeg format to a variable
            resolve(upload_imageBase64);
          }
        }

      } catch (e) {
        reject(e);
      }

    });

  }


  /**
   * 
   * @param base64data 
   * @returns 
   * 
   * Given Function rotatethe image to 
   * left by 90 deg at a time and return 
   * the base64 Encoded value
   * 
   * 
   */
  private rotateImageLeft90Degree(base64data) {
    return new Promise((resolve, reject) => {
      let canvas = <HTMLCanvasElement>document.getElementById("c");
      let ctx = canvas.getContext("2d");
      let image = new Image();
      image.crossOrigin = "anonymous";
      if (this.rotateClicked == 1) {
        image.src = base64data + '?t=' + new Date().getTime();
      } else {
        image.src = base64data
      }
      this.rotateClicked += 1;
      this.actionPerformed = true;
      image.onload = function () {
        try {
          canvas.width = image.height;
          canvas.height = image.width;
          canvas.toDataURL("image/jpeg");
          ctx.rotate(-90 * Math.PI / 180);
          ctx.translate(-canvas.height, 0);
          ctx.drawImage(image, 0, 0);

          resolve(canvas.toDataURL());
        } catch (e) {
          reject(e);
        }
      };
    });
  }


  /**
   * 
   * @param base64data 
   * @returns 
   * This function take the image public url
   * rotates an image by 90deg
   * once and return the base64 Encoded 
   * image
   * 
   */

  private rotateBase64Image90Degree(base64data) {
    return new Promise((resolve, reject) => {
      let canvas = <HTMLCanvasElement>document.getElementById("c");
      let ctx = canvas.getContext("2d");
      let image = new Image();
      image.crossOrigin = "anonymous";
      if (this.rotateClicked == 1) {
        image.src = base64data + '?t=' + new Date().getTime();
      } else {
        image.src = base64data
      }
      this.rotateClicked += 1;
      this.actionPerformed = true;
      image.onload = function () {
        try {
          canvas.width = image.height;
          canvas.height = image.width;
          canvas.toDataURL("image/jpeg");
          ctx.rotate(90 * Math.PI / 180);
          ctx.translate(0, -canvas.width);
          ctx.drawImage(image, 0, 0);
          resolve(canvas.toDataURL("image/jpeg", 100));
        } catch (e) {
          reject(e);
        }
      };
    });
  }



  /**
   * 
   * @param SummaryRow 
   * @param content 
   * 
   * Implemented on 09-08-2023
   * This function opens the popup
   * for the image rotate and replace
   * 
   * 
   */
  editImageAnswer(SummaryRow, content) {
    //console.log(questionId)
    this.croppedImage = '';
    let imgUrl = $("#image-" + SummaryRow.QuestionId).attr("src");
    this.publicUrl = imgUrl
    this.UploadBtnText = 'Replace'
    this.croppedImage = imgUrl
    this.tanNotFound = false;
    this.Image_rotete_status = '';
    this.imageBase64 = '';
    this.imagebase64Updated = 'hidden_class';
    this.imagebase64NotShow = '';
    this.Image_upload_btn_status = '';
    this.imageBase64 = imgUrl
    this.open(content, { backdrop: 'static', ignoreBackdropClick: true });
  }


  /**
   * 
   * @param content 
   * 
   * This update the existing image 
   * when user click 
   * on edit icon for answer image
   * either change or rotate image and click on Save 
   */
  updateImage(content) {
    if (this.actionPerformed) {
      let param = {};
      let imageDetails = this.publicUrl.split('/');

      /**
       *  Technicain submited survey image path
       * https://s3.us-east-1.amazonaws.com/cams2021/images/11759/204028/8bdfd11c-9678-44af-b8ff-d5366e3db7f7.png
       * Customer submited survey image path
       * https://s3.us-east-1.amazonaws.com/cams2021/images/200248/7fd1449b-5c8d-4b7f-9268-55aea0b42762.png?t=1693212119974
       * 
       * if block execute if survey is 
       * submited by technician
       * else block is executed if survey is submited by customer as submited survey 
       * does not have jobID
       * 
       */

      if (imageDetails[7]) {
        //If the survey is submited by technicain it contains atmid and jobid
        let ImagName = imageDetails[7].split('?');
        param = {
          "imagesBase64": this.imageBase64,
          "imageName": imageDetails[4] + '/' + imageDetails[5] + '/' + imageDetails[6] + '/' + ImagName[0],

        }
      } else {
        //If the survey is submited by customer it contains only jobid
        let ImagName = imageDetails[6].split('?');
        param = {
          "imagesBase64": this.imageBase64,
          "imageName": imageDetails[4] + '/' + imageDetails[5] + '/' + ImagName[0],

        }
      }


      this.updateLoader = true
      this.service.editImage(param).subscribe({

        next: (response: any) => {
          this.tagUpdateloader = false;
          this.tagUpdateBtnclass = '';
          this.close();
          this.updateLoader = false
          this.isLoading = true;
          let jobDetails = JSON.parse(localStorage.getItem('jobDetails'));
          this.openApprovalDetailsInternal(jobDetails);
          this._snack$.notifySuccess(response.message, 'Success');

        },
        error: (err: Error) => {
          this.updateLoader = false
          this._snack$.notifyError(err['error'].message, 'Error');
          //console.error(err['error'].message);
        },
        complete: () => {
          this.updateLoader = false
        },
      });

    } else {
      this._snack$.notifyError('Please perform some action i.e(Rotate Left,Right Or Replace)', 'Error');

    }
  }


  ImageTagUpdate() {


    this.tagUpdateloader = true;
    this.tagUpdateBtnclass = 'disabled-btn';
    var ext = this.currentupdateDetailsParam.Extension;
    var image_name = this.currentupdateDetailsParam.ImageId;
    var updateImageTAG = $("#updateImageTAGInd option:selected").val();
    this.updateDetailsParam = {
      "answer": [],
      // "answer_type_value_id": "",
      "job_id": "",
      "atm_id": "",
      "service_instance_id": "",
      //"service_instance_answer_id": "",
      "question_id": "",
      "comment": "",
      "image_id": "",
      "image_ext": "",
      "image_tag": "",
      "images": []
    }

    this.updateDetailsParam.answer.push({ "answer_type_value_id": this.currentupdateDetailsParam.answer_type_value_id.toString(), "ServiceInstanceAnswerId": this.currentupdateDetailsParam.ServiceInstanceAnswerId.toString() });
    //this.updateDetailsParam.answer_type_value_id = this.currentupdateDetailsParam.answer_type_value_id.toString();
    this.updateDetailsParam.job_id = this.currentupdateDetailsParam.JobId.toString();
    this.updateDetailsParam.atm_id = this.currentupdateDetailsParam.ATMID.toString();
    this.updateDetailsParam.service_instance_id = this.currentupdateDetailsParam.ServiceInstanceId.toString();
    this.updateDetailsParam.question_id = this.currentupdateDetailsParam.QuestionId.toString();
    //this.updateDetailsParam.service_instance_answer_id = this.currentupdateDetailsParam.ServiceInstanceAnswerId.toString();
    if (this.currentupdateDetailsParam.Comments == null) {
      this.updateDetailsParam.comment = '';
    }
    else {
      this.updateDetailsParam.comment = this.currentupdateDetailsParam.Comments.toString();
    }

    this.updateDetailsParam.image_id = image_name.toString();
    this.updateDetailsParam.image_ext = ext.toString();
    this.updateDetailsParam.image_tag = updateImageTAG.toString();

    this.service.get_approval_tool_question_answer_update(this.updateDetailsParam).subscribe({

      next: (response: any) => {
        this.tagUpdateloader = false;
        this.tagUpdateBtnclass = '';
        this.close();
        this.isLoading = true;
        this.openApprovalDetailsInternal(this.currentupdateDetailsParam);


      },
      error: (err: Error) => {
        console.error(err['error'].message);
      },
      complete: () => {
      },
    });

  }


  //-- IMG cropper start











  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    this.showCropper = false;
    this.flipAfterRotate();
  }
  cropperReady(sourceImageDimensions: Dimensions) {
    // cropper ready
    console.log('Cropper ready', sourceImageDimensions);
  }
  loadImageFailed() {
    // show message
    console.log('Load failed');
  }

  clear() {
    this.croppedImage = '';
    this.imageChangedEvent = '';
    this.myInputVariable.nativeElement.value = '';
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation,
    };
  }

  historyImageView(imageUrl) {
    const dialogRef = this.dialog.open(DialogSingleImage, {
      width: '90%',
      height: '90%',
      data: { imageURL: imageUrl },
    });
  }

  historyImageViewAnswer(summaryRow) {
    let imgUrl = $('#image-' + summaryRow.QuestionId).attr('src');
    const dialogRef = this.dialog.open(DialogSingleImage, {
      width: '90%',
      height: '90%',
      data: { imageURL: imgUrl },
    });
  }


  //-- IMG cropper end

  addSlide() {
    //this.slides.push({ img: "http://placehold.it/350x150/777777" })
  }

  removeSlide() {
    // this.slides.length = this.slides.length - 1;
  }

  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  afterChange(e) {
    console.log(e);
  }

  beforeChange(e) {
    console.log('beforeChange');
  }

  changeMainImage(img, imgTag, SummaryRow) {

    this.isLoading = true;
    let randomnum = new Date().getTime();
    //let Img = baseUrl + 'images/'+SummaryRow.JobId+'/'+SummaryRow.ATMID+'/' + img.imageId + '.' + img.extension;
    //Img = "https://s3.us-east-1.amazonaws.com/cams2021/images/11728/200254/56e3274c-48cf-11ed-8f66-0aae957e44a7.jpg";
    $("#image-" + SummaryRow.QuestionId).attr("src", img + '?t=' + randomnum);
    $("#image-tag-" + SummaryRow.QuestionId).html(imgTag);

    this.setTimeout = setTimeout(() => {
      this.isLoading = false;
    }, 1000)


  }


  /**
   * 
   * @param summaryRow 
   * @param content_answer 
   * @param answerTypeValueId 
   * 
   * THis function is called whenever 
   * a user try to change the answer 
   * for a given question
   * 
   * Implemented on on 12-07-2023
   * 
   */

  changeAnswer(summaryRow, content_answer, answerTypeValueId) {
    this.answerTypeValueId = [];
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "You want to change the Answer!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, change it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.isLoading = true;
        this.onItemChange('radio', '', summaryRow, '', '')
        this.service.get_approval_tool_image_tag({ 'answer_type_value_id': answerTypeValueId }).subscribe({
          next: (response: any) => {
            localStorage.setItem('quesDetails', JSON.stringify(summaryRow))

            /**
             * If there is any image tag 
             * so the provision to uplaod new Images
             * else Update the answer directly
             */
            if (response.result.summary_list.length > 0) {
              this.open(content_answer, { backdrop: 'static', ignoreBackdropClick: true });

              this.answerTypeValueId = response.result.summary_list;
              this.answerTypeImages = [];
              if (summaryRow.question_images.length > 0) {

                const _that = this;
                summaryRow.question_images.forEach(function (item, index) {
                  _that.answerTypeImages.push(
                    {
                      "DynamicImageTagID": '',
                      "imageId": item.imageId,
                      //"extension": item.extension,
                      "publicUrl": item.publicUrl,
                      //"ImageTag": item.ImageTag,
                      'is_selected': false,
                      'uploaded_for': ''
                    }
                  )
                });
              }
              this.isLoading = false;
            } else {
              //Call this function to directly Update the Answer
              this.updateAnswer()
            }

          },
          error: (err: Error) => {
            console.error(err['error'].message);
          },
          complete: () => {
          },
        });
      } else {
        //When Clicked on cancel
        let jobDetails = JSON.parse(localStorage.getItem('jobDetails'));
        this.openApprovalDetailsInternal(jobDetails);
        //this.openApprovalDetailsInternal(summaryRow)
      }
    })

  }







  /**
   * 
   * @param event 
   * This function get the image
   * validate the extension
   * convert the image to base64Encoding
   * and pass the data to upload image Function
   * 
   * Implemented on on 12-07-2023
   * 
   */
  onFileChange(event, dynamicImageTagID) {
    let quesDetails = JSON.parse(localStorage.getItem('quesDetails'));
    let files = event.target.files;
    let file = files[0];
    const _that = this; // Hold the reference of this ina variable
    const extension = file.name.split('.').pop();
    const validExtension = ["jpg", "jpeg", "png"];
    if (!validExtension.includes(extension)) {
      this._snack$.notifyError('Invalid file format only jpg,jpeg,png allowed', 'Error');
      return
    }


    if (files && file) {
      var reader = new FileReader();
      reader.onload = readSuccess;
      function readSuccess(evt) {
        this.base64textString = btoa(<string>evt.target.result);
        console.log(this.base64textString);
        const param = {
          "JobId": quesDetails.JobId,
          "ATMID": quesDetails.ATMID,
          "images": [
            {
              "imagesBase64": this.base64textString,
              "extention": extension,
              'details': '',
              "created_by": localStorage.getItem('user_id'),
              "created_by_role": localStorage.getItem('role')
            }
          ]
        }
        _that.uploadImage(param, quesDetails, dynamicImageTagID)
      };
      reader.readAsBinaryString(file);
    }

  }


  /**
 * 
 * @param dynamicImageTagID 
 * @param imageTagId 
 * This function assigin 
 * the image to a paticular dynamicImageTagId
 * when the user select an image inseide 
 * the accordian 
 * assignImage method is added in the radio
 * button selection
 * 
 * Implemented on on 12-07-2023
 * 
 */

  assignImage(dynamicImageTagID, imageTagId) {
    this.answerTypeImages.forEach(function (item, index) {

      if (item.DynamicImageTagID == dynamicImageTagID) {
        item.is_selected = false;
        if (item.uploaded_for == '' && item.uploaded_for != dynamicImageTagID) {
          //alert(item.uploaded_for + '--' + dynamicImageTagID)
          //console.log('---------jjjjj---------');
          //console.log(item);
          //console.log('-----------------------');
          item.DynamicImageTagID = '';
        }
      }

      if (item.imageId == imageTagId) {
        item.DynamicImageTagID = dynamicImageTagID;
        item.is_selected = true;
      }
    });


    console.log(this.answerTypeImages);
  }



  /**
   * 
   * @param param 
   * @param quesDetails 
   * @param dynamicImageTagID 
   * 
   * This function upload the images
   * using the new API
   * Implemented on 12-07-2023
   * 
   */
  uploadImage(param, quesDetails, dynamicImageTagID) {
    this.isImageUpload = true;
    this.service.uploadImage(param).subscribe({
      next: (response: any) => {
        if (response.imgList.length > 0) {

          const _that = this;

          //deselct the selected DynamicImageTagID if any when new image is uploaded
          this.answerTypeImages.forEach(function (item, index) {
            if (item.DynamicImageTagID == dynamicImageTagID) {
              //item.DynamicImageTagID = '';
              item.is_selected = false;
              if (item.uploaded_for != dynamicImageTagID) {
                // alert(item.uploaded_for + '--' + dynamicImageTagID)
                item.DynamicImageTagID = '';
              }
            }
          });


          response.imgList.forEach(function (item, index) {
            _that.answerTypeImages.push(
              {
                "DynamicImageTagID": dynamicImageTagID,
                "imageId": item.imageId,
                //"extension": 'jpg',
                "publicUrl": item.publicUrl,
                //"ImageTag": 'dddd',
                "is_selected": true,
                "uploaded_for": dynamicImageTagID
              }
            )
          });
        }

        this.isImageUpload = false;
      },
      error: (err: Error) => {
        this.isImageUpload = false;
        this._snack$.notifyError(err['error'].message, 'Error');
        console.error(err['error'].message);
      },
      complete: () => {
        this.isImageUpload = false;
      },
    });
  }


  public resetSelection() {
    const availableImageList = this.answerTypeImages;
    const _that = this;
    this.answerTypeImages = [];
    availableImageList.forEach(function (item, index) {
      _that.answerTypeImages.push(
        {
          "DynamicImageTagID": "",
          "imageId": item.imageId,
          //"extension": 'jpg',
          "publicUrl": item.publicUrl,
          //"ImageTag": 'dddd',
          "is_selected": false,
          "uploaded_for": item.uploaded_for
        }
      )
    });
  }

  /**
   * This function is responsible 
   * for saving the answer with multiple Image
   * based on the Dynamic image Tag ID
   */
  public saveImageAnswer(answerTypeValueId) {

    let submitImages = [];
    let extension = '';
    let summeryRow = JSON.parse(localStorage.getItem('quesDetails'));
    const _that = this; // <-- Store a reference to the current context
    _that.answerTypeImages.forEach(function (item, index) {
      extension = item.publicUrl.split('.').pop();
      if (item.is_selected == true) {
        submitImages.push(
          {
            "DynamicImageTagID": item.DynamicImageTagID,
            "imageId": item.imageId,
            "extension": extension,
            "publicUrl": item.publicUrl,
          }
        )
      }
    });

    this.updateDetailsParam.image_id = '--';
    this.updateDetailsParam.image_ext = '--';
    this.updateDetailsParam.images = submitImages;

    // console.log(this.updateDetailsParam);
    this.isImageUpload = true;
    this.saveAnswerBtn = true

    if (submitImages.length == 0) {
      this.isImageUpload = false;
      this.saveAnswerBtn = false;
      this._snack$.notifyError("Please select an image", 'Error');
      return
    } else {
      const mismatchedId = answerTypeValueId.find(obj1 => !submitImages.some(obj2 => obj2.DynamicImageTagID === obj1.DynamicImageTagID));
      if (mismatchedId) {
        this._snack$.notifyError("Please select an image for " + mismatchedId.ImageTag, 'Error');
        this.isImageUpload = false;
        this.saveAnswerBtn = false;
        return
        //console.log("ID with mismatched name:", mismatchedId.DynamicImageTagID + '--' + mismatchedId.ImageTag);
      }
    }

    //Call the Function to update the Images 
    this.updateAnswer()
  }

  /***
   * This Section is to upload the answer
   * 
   *  
   */
  public updateAnswer() {

    this.service.get_approval_tool_question_answer_update(this.updateDetailsParam).subscribe({

      next: (response: any) => {
        this.saveAnswerBtn = false;
        this.isImageUpload = false;
        this.close();
        let jobDetails = JSON.parse(localStorage.getItem('jobDetails'));
        this._snack$.notifySuccess(response.message, 'Success');
        this.openApprovalDetailsInternal(jobDetails);
        //this.openApprovalDetailsInternal(summeryRow);
      },
      error: (err: Error) => {
        this.isImageUpload = false;
        this.saveAnswerBtn = false;
        this._snack$.notifyError(err['error'].message, 'Error');
        console.error(err['error'].message);
      },
      complete: () => {
        this.isImageUpload = false;
        this.saveAnswerBtn = false;

      },
    });
  }

  public Showselection(QuestionId) {
    this.showAnswer = QuestionId;

  }

  public ShowsComment(QuestionId) {
    this.showComment = QuestionId;

  }

  ngOnDestroy() {
    clearTimeout(this.setTimeout);
  }
  public changeDateFormat(date){
    let re = /\-/gi;
    if(date && date!='00-00-0000')
      return date.replace(re, "/");
    else
      return 'N/A'
  }

}


function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

function CrossOrigin(arg0: string) {
  throw new Error('Function not implemented.');
}
