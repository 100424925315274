import { Component,  OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss']
})
export class OtpVerificationComponent implements OnInit {
  public todayDate: Date = new Date();
 
  public form: FormGroup;
  public error_message="";
  public submitted =false ;
  username="";
  constructor(
    private _router: Router,
    private _service: AuthService,
    private _snack: SnackbarService
  ) { }

  

  ngOnInit(): void {
    this.username = localStorage.getItem("username");
    localStorage.removeItem('username');
    this.form = new FormGroup({
      username: new FormControl({value: this.username, disabled: true}),
      otp: new FormControl("", [Validators.required]),
    });
  }


  public verifyOtp(): void {
    if (this.form.valid) {
      this.submitted=false;
      let params = {
        "username":this.username,
        "code":this.form.controls.otp.value,
      }

     this._service.verifyOtp(params).subscribe((response)=>{

      if(response.code==200){
        this._snack.notifySuccess('OTP Verified Successfully', 'Success');
        this._router.navigate(['login']);
      }
      else{
          this.error_message = response.message
      }
     });
    }else{
      this.submitted=true;
      
    }
  }

}
