import { Component, OnInit, Inject, ElementRef, ViewChild, Compiler } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { trigger, style, animate, transition, group, state } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {Sort, MatSort} from '@angular/material/sort';
import { PieChartData } from 'src/app/pages/customer/models/pie-chart-data';
import { JobService } from './services/job.service';             
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ChartsService } from './../customer/services/charts.service';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery';
import { forkJoin, Observable } from 'rxjs';

import { ServiceTicketModalComponent } from '../service-ticket-modal/service-ticket-modal.component';
import { CreateJobComponent } from '../create-job/create-job.component';
import { AssetsService } from '../assetlink/services/assets.service';

export interface ImageViewPopUp {
  imageURL: string;
}

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
          'max-height': '500px', 'opacity': '1', 'visibility': 'visible'
      })),
      state('out', style({
          'max-height': '0px', 'opacity': '1', 'visibility': 'hidden'
      })),
      transition('in => out', [group([
          animate('1ms ease-in-out', style({
              'opacity': '0'
          })),
          animate('200ms ease-in-out', style({
              'max-height': '0px'
          })),
          animate('300ms ease-in-out', style({
              'visibility': 'hidden'
          }))
      ]
      )]),
      transition('out => in', [group([
          animate('1ms ease-in-out', style({
              'visibility': 'visible'
          })),
          animate('300ms ease-in-out', style({
              'max-height': '500px'
          })),
          animate('400ms ease-in-out', style({
              'opacity': '1'
          }))
      ]
      )])
  ]),
  ],
})
export class JobComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public pieChartData$: Observable<PieChartData>
  public jobYearLookup : any; 
  public jobIdLookup : any;
  public customerId = '';
  public jobStatusId = '';
  public jobYear = '';
  public jobMonth = '';
  public startDate = '';
  public isLoading = false;
  public jobStatusLookup : any;
  public jobId = '';
  public imageFullURL:string = '';
  public baseUrl: string = environment.S3Url;
  public assetHistoryList: any = [];
  totalrecordFound = 0;
  assignmentprofileTotalRecord: number = 0;
  selectedJobId: number;
  assignmentprofileData = []; //interface needs to be made
  public graphItems = [];
  animationState = 'out';
  dataSource: any;
  profileDataSource: any;
  displayedColumns: string[] = [
    'assetID',
    'atmDescription',
    'address',
    'city',
    'state',
    'zip',
    'assignedDate',
    'dueDate',
    'serviceInstanceStatus',
    'service',
    'editJob'
  ];

  public jobStatusText = 'All';
  public jobYearText = 'All';
  public jobMonthText = 'All';
  public jobNumberText = 'All';

  public getJobListingParams = {
    "CustomerId": "",
    "JobStatusId": "",
    "StartDate":"",
    "JobNumber":"",
    "JobName":"",
    "RowIndex":"",
    "RowItem":""
  };


  public getJobIdsParams = {
    "company_id": '',
    "job_status_id": '',
    "year" : '',
    'month' : '',
    'job_id' : '',
    'customerid' : '',
    'job_number' : '',
    'RowIndex': '',
    'RowItem': ''
  };

  createUpdateAssignmentModal: BsModalRef;
  createJobModal: BsModalRef;

  constructor(
    private service: JobService,
    private _assetService$: AssetsService,
    private _chartService$: ChartsService,
    private modalService: BsModalService,
    public dialog: MatDialog,
  ) {}


  ngOnInit(): void {
    console.log('eeeeeee');
    this.customerId = localStorage.getItem('customer_id');
    this.service.load_status_list().subscribe((response) => {
      this.jobStatusLookup = response;
    });

    this.get_years();
    this.getJobIds();
    this.getJobListing();
  }
  
  public jobTabIndex = 1;
  public() {
    const tabCount = 3;
    this.jobTabIndex = (this.jobTabIndex + 1) % tabCount;
  }

  get_years() {
    this.jobYearLookup = [];

    for (let i = 2000; i <= new Date().getFullYear(); i++) {
      this.jobYearLookup.push(i);
    }
    return this.jobYearLookup;
  }

  getJobIds() {
    this.isLoading = true;
    this.jobIdLookup = [];
    this.getJobIdsParams.company_id = this.customerId;
    this.getJobIdsParams.job_status_id = this.jobStatusId;
    this.getJobIdsParams.year = this.jobYear;
    this.getJobIdsParams.month = this.jobMonth;
    this.getJobIdsParams.RowIndex = "";
    this.getJobIdsParams.RowItem = "";
    this.service.get_job_ids(this.getJobIdsParams).subscribe( {      
      next: (response: any) => {        
      if (response.code == 200) {
        this.jobIdLookup = response.data.job;
      }
    },
    error: (err: Error) => {
      console.error(err['error'].message);
    },
    complete: () => {
      //this.isLoading = false;
    },
    });
    
  }

  getJobListing(rowIndex?: string, rowItem?: string) {
    this.isLoading = true;
    this.getJobListingParams.CustomerId = this.customerId;
    this.getJobListingParams.JobStatusId = this.jobStatusId;
    this.getJobListingParams.RowIndex = rowIndex ? rowIndex.toString() : "0";
    this.getJobListingParams.RowItem = rowItem ? rowItem.toString() : "10";
    this.getJobListingParams.JobNumber = this.jobId;
    this.getJobListingParams.StartDate = this.startDate;
    this.service.get_job_listing(this.getJobListingParams).subscribe( {
      
      next: (response: any) => { 
        if(response.data) {
          this.totalrecordFound = response.data.assetCounts;
          this.totalrecordFound = response.data.assetCounts;
          this.dataSource = new MatTableDataSource<any>(response.data.tableRows);
          this.dataSource.sort = this.sort;
          if(!rowIndex && !rowItem) {
            this.dataSource.paginator = this.paginator;
          }
          this.graphItems = response.data.graphItems;
          this.graphItems.forEach((element, index, array) => {
            this._chartService$.loadPieChartData(element).subscribe((response) => {
              response.series[0] = this.findPercentage(this.totalrecordFound, response.series[0]);
              element.pieChartData$ = response;
            })
          });
      }
      
    },
    error: (err: Error) => {
      console.error(err['error'].message);
    },
    complete: () => {
      this.isLoading = false;
    },
    });
    
  }

  onPaginateChange(event){
    this.getJobListing(event.pageIndex, event.pageSize);
  }

 /*  openProfilelDetails(jobId, rowIndex?: string, rowItem?: string) {
    let RowIndex = rowIndex ? rowIndex.toString() : "0";
    let RowItem = rowItem ? rowItem.toString() : "10";
    this.selectedJobId = jobId;
    console.log('profileDetails', jobId);
    this.isLoading = true;

    this.service.get_job_details({JobId: this.selectedJobId, RowIndex: RowIndex, RowItem: RowItem}).subscribe( {
    next: (response: any) => {
      console.log('response', response);
      this.assignmentprofileData = response.data.serviceInstances;
      this.assignmentprofileTotalRecord = response.data.total;
      this.profileDataSource = new MatTableDataSource<any>(response.data.serviceInstances);
      console.log('this.profileDataSource', this.profileDataSource);
      this.profileDataSource.paginator = this.paginator;
      this.profileDataSource.sort = this.sort;
      this.isLoading = false;
      this.jobTabIndex = 2;
    },
    error: (err: Error) => {
      console.error(err['error'].message);
    },
    complete: () => {
      //this.isLoading = false;
    },
    });
  } */

  openProfilelDetails(jobId, atmId, rowIndex?: string, rowItem?: string) {
    let RowIndex = rowIndex ? rowIndex.toString() : "0";
    let RowItem = rowItem ? rowItem.toString() : "10";
    let atm_id = atmId;
    this.selectedJobId = jobId;
    this.isLoading = true;
    forkJoin([
      this.service.get_job_details({JobId: this.selectedJobId, RowIndex: RowIndex, RowItem: RowItem}),
      this._assetService$.assetListHistoryAPI({
        atm_id: atm_id.toString(),
      }),
    ]).subscribe({
      next: (response: any) => {
         if (response[0].code === 200) {
          this.assignmentprofileData = response[0].data.serviceInstances;
          this.assignmentprofileTotalRecord = response[0].data.total;
          this.profileDataSource = new MatTableDataSource<any>(response[0].data.serviceInstances);
          this.profileDataSource.paginator = this.paginator;
          this.profileDataSource.sort = this.sort;
        }
        if (response[1].code === 200) {
          this.assetHistoryList = response[1].result.q_list;
        }
       /* if (response[2].Code === 200) {
          this.assetImageList = response[2].result.image_list;
        }
        if (response[3].Code === 200) {
          this.assetHistoryList = response[3].result.q_list;

          //console.log(this.assetHistoryList[1].question_list[1].JobId , "DATACHECK");
        } */
      },
      error: (err: Error) => {
        //this._snack$.notifyError(err['error'].message, 'OK');
      },
      complete: () => {
        //calling the asset filters
        this.jobTabIndex = 2;
        this.isLoading = false;
        /* this.assetFilters();
        this.isLoading = false;
        this.selectedIndex = 1;
        this.assetProfileTab = false;
        this.mapTab = false;
        this.actionItemsTab = false;
        this.imageTab = false;
        this.historyTab = false;
        this.assetListTab = false;
        this.assetFilterPanelFlag = false; */
      },
    });
  }

  clicktoseehistory = (index, JobId) => {
    console.log('clicktoseehistory', index);
    //this.hideIsIssue(JobId);
    //alert('working');
    let element = document.getElementById('history_details_' + index);
    //Hide all Elements
    const elements = Array.from(
      document.getElementsByClassName(
        'myhistory'
      ) as HTMLCollectionOf<HTMLElement>
    );
    for (var i = 0; i < elements.length; i++) {
      if (i != index) {
        elements[i].style.display = 'none';
      }
    }
    //alert(element.style.display )
    if (element.style.display == 'none') {
      element.style.display = 'table';
    } else {
      element.style.display = 'none';
    }

    //hideIsIssue()
  };

  hideIsIssue(JobId)
  {
    document.getElementById('isIssueDiv_'+JobId).style.display = "none";
  }

  onProfilePaginateChange(event){
    console.log('this.selectedJobId', this.selectedJobId);
    this.openProfilelDetails((this.selectedJobId), event.pageIndex, event.pageSize);
  }

  filterByDataCollect(dataValue:any, dataType:any, event : Event)
  {
    let selectElementText = event.target['options']
      [event.target['options'].selectedIndex].text;
    this.isLoading = true;
    if(dataType == 'job_status')
    {      
      this.jobStatusId = dataValue;
      if(selectElementText == 'Job Status')
      {
        selectElementText = 'All';
      }
      this.jobStatusText = selectElementText;
      //this.getJobIds();

    }
    else if(dataType == 'job_year')
    {
      this.jobYear = dataValue;
      
      if(selectElementText == 'Job Year')      
      {
        selectElementText = 'All';
      }
      this.jobYearText = selectElementText;
      //this.getJobIds();
    }
    else if(dataType == 'job_month')
    {
      this.jobMonth = dataValue;
      if(selectElementText == 'Job Month')
      {
        selectElementText = 'All';
      }
      this.jobMonthText = selectElementText;
      //this.getJobIds();
    }
    else if(dataType == 'job_no')
    {
      this.jobId = dataValue;
      if(selectElementText == 'Job Number')
      {
        selectElementText = 'All';
      }
      this.jobNumberText = selectElementText;
      //this.getJobIds();
    }
    else
    {
      // do nothing
    }

    if(this.jobYear != '' && this.jobMonth != '')
    {
      this.startDate = this.jobMonth+"-"+this.jobYear;
    }
    else if(this.jobYear != '')
    {
      this.startDate = this.jobYear;
    }
    else if(this.jobMonth != '')
    {
      this.startDate = this.jobMonth;
    }
    else{
      this.startDate = '';
    }

    this.getJobListing();
    
  }

  toggleShowDiv() {
    this.animationState = this.animationState === 'out' ? 'in' : 'out';
  }

  findPercentage(total, num): number
  {
    return parseFloat((num/total * 100).toFixed(4));
  }

  findNumber(total, per)
  {
    return ((per/100)*total);
  }

  /**
   *Open Modals Action Modal
   */
   openActionModal(value: string, id?: string) {
    if(value ==='reassign') {
      this.opencreateUpdateModal(value);
    }
    else if (value ==='create' || value ==='edit') {
      this.openCreateModal(value, id);
    }
    else if(value === 'approve') {
      this.updateServiceInstanceUpdate(value);
    }
    else {
      this.updateServiceInstanceUpdate(value);
    }
    
  }

  updateServiceInstanceUpdate(statusType: string) {
    this.isLoading = true;
    if(confirm(`Are you sure want to ${statusType} this item?`)) {
      let requestBody = {
        "ServiceInstanceId": this.selectedJobId,
        "type": statusType
      };
      this.service.update_service_ticket(requestBody).subscribe({
        next: (response: any) => {
          if(response) {
            this.isLoading = false;
          }
          alert(response.message);
        },
        error: (err: Error) => {
          console.error(err['error'].message);
        },
        complete: () => {
          //this.isLoading = false;
        },
        });
    }
    else {
      this.isLoading = false;
    }
  }

  opencreateUpdateModal(type: string) {
    this.createUpdateAssignmentModal = this.modalService.show(ServiceTicketModalComponent, {
      initialState: {
        title: type,
        data: {selectedID: this.selectedJobId}  
      },
    });
  }

  openCreateModal(value: string, id: string) {
    this.createJobModal = this.modalService.show(CreateJobComponent, {
      initialState: {
        title: value,
        data: id ? {JobId: id} : ''
      },
    });
    this.createJobModal.onHide.subscribe(result => {
      this.getJobIds();
     });
  }

  historyImageView(index)
   {
    this.imageFullURL = this.baseUrl+'images/'+index.JobId+'/'+index.ATMID+'/'+index.ImageId+'.'+index.Extension;
    const dialogRef = this.dialog.open(DialogSingleImage, {
      width: '90%',
      height: '90%',
      data: {imageURL: this.imageFullURL},
    });    
   }

}

// just an interface for type safety.
interface marker {
  lat: number;
  lng: number;
  label?: string;
  zoom?: number;
  draggable?: boolean;
  disableAutoPan?: boolean;
}
import { NgxImageZoomModule } from 'ngx-image-zoom';
import * as moment from 'moment';

@Component({
  selector: 'dialog-single-image',
  templateUrl: 'dialog-single-image.html',
  styleUrls: ['job.component.css'],
})
export class DialogSingleImage {  
  constructor(
    public dialogRef: MatDialogRef<DialogSingleImage>,
    @Inject(MAT_DIALOG_DATA) public data: ImageViewPopUp
    
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

