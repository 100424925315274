import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../services/customer.service';
import { PieChartData } from 'src/app/pages/customer/models/pie-chart-data'
import { Observable } from 'rxjs';
import { ChartsService } from '../../services/charts.service';
@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css'],
})
export class CustomerComponent implements OnInit {
  public pieChartData$: Observable<PieChartData>
  public tilesList: any = [];
  public actionAsset: any = [];
  public isLoading: boolean = true;
  public subCategory : any;
  public customer_id ;
  constructor(
    private _customerService$: CustomerService,
    private _chartService$: ChartsService
  ) {}
  ngOnInit(): void {
    /* calling the getTileslist*/
    this.customer_id=localStorage.getItem('customer_id');
    this.getTilesList();
  }
  /*
   * tiles list functionality
   */
  public getTilesList = (): void => {
    this._customerService$.listAllTilesAPI({ customer_id: this.customer_id }).subscribe({
      next: (response: any) => {
        if (response.Code === 200) {
          this.actionAsset= response.data;
          this.tilesList = response.data.tiles;
          if(this.tilesList.length > 0){
            this.tilesList.forEach((element, index, array) => {
              this._chartService$.loadPieChartData(element).subscribe((response) => {
                element.pieChartData$ = response;
              })
              element.subCategory =[];
              if(Object.keys(element.subCat).length > 0){
                  let i=0;
                  for (const property in element.subCat) {
                    let x= {};
                    x['name'] = property;
                    x['count'] = element.subCat[property];
                    element.subCategory.push(x)
                  }
              }
            })

          }
         
        }
      },
      error: (err: Error) => {
        console.error(err['error'].message);
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  };
}
