<div class="modal-body">
	<!-- <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
		<span aria-hidden="true">&times;</span>
	  </button> -->
	<form [formGroup]="ignoreForm">
		<div class="popTop">
			<img src="./assets/images/cams-logo-black.png" alt="">
			<span>Ignore Issue</span>
		</div>

		<div class="popMid">
			<p>Issue Type:{{data.IssueType}}</p>
			<p>Issue Description:  {{data.IssueDescription}}</p>
			<mat-form-field class="example-full-width" appearance="fill">
				<!-- <mat-label>Leave a comment</mat-label> -->
				<textarea class="popComntBox" formControlName="comment" matInput placeholder="Comment(Required)" maxlength=100></textarea>
			</mat-form-field>
			<div  *ngIf="(ignoreForm.controls['comment'].invalid && (ignoreForm.controls['comment'].dirty || ignoreForm.controls['comment'].touched)) || (ignoreForm.controls['comment'].invalid && submitted)">
				<div class="alert alert-danger" role="alert" *ngIf="ignoreForm.controls['comment'].errors.required">
				Please enter comment
				</div>
			</div>
		</div>
		<div class="popBtm">
			<button mat-button (click)="onCancel()">Cancel</button>
			<button mat-button (click)="onConfirm()">Ignore Issue</button>
		</div>
	</form>
	
</div>