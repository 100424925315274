export enum colors {
  YELLOW = '#ffc260',
  BLUE = '#536DFE',
  LIGHT_BLUE = '#F8F9FF',
  PINK = '#FF468D',
  GREEN = '#3CD4A0',
  VIOLET = '#9013FE',
  GREY ='#888888',
  
}
