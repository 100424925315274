import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssetlinkComponent } from './components/assetlink/assetlink.component';

import { MatTableModule } from '@angular/material/table';
import { NgxEchartsModule } from 'ngx-echarts';
import { TrendModule } from 'ngx-trend';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { NgApexchartsModule } from 'ng-apexcharts';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SharedModule } from '../../shared/shared.module';
import { AgmCoreModule } from '@agm/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { IgnoreitemComponent } from '../ignoreitem/ignoreitem.component';
import { SendassettoemailComponent } from '../sendassettoemail/sendassettoemail.component';
import { BrowserModule } from '@angular/platform-browser';
import { DialogSingleImage } from './components/assetlink/assetlink.component';
import { NgxImageZoomModule } from "ngx-image-zoom"; // <-- Add this line


@NgModule({
  declarations: [AssetlinkComponent, DialogSingleImage],
  bootstrap:    [ DialogSingleImage ],
  imports: [
    CommonModule,

    MatTableModule,
    NgxEchartsModule,
    TrendModule,
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatProgressBarModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatGridListModule,
    MatSelectModule,
    MatInputModule,
    NgApexchartsModule,
    FormsModule,
    SharedModule,
    BrowserModule,    
    BsDatepickerModule.forRoot(),
    AgmCoreModule.forRoot({
      //apiKey: 'AIzaSyBfBIdnoep29WLp5wKXpqeX0ZDORi_hQSY',
      apiKey: 'AIzaSyBObDp0iK-EC8viHD0Cquwv5ttoON1KTQs',
    }),
    NgxImageZoomModule,
    

    
  ],
  
  entryComponents:[IgnoreitemComponent,SendassettoemailComponent]
})
export class AssetlinkModule {}
