import { Component } from '@angular/core';
import { routes } from '../../consts/routes';
import {Router} from "@angular/router"

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  public routes: typeof routes = routes;
  public isOpenUiElements = false;
  public urlCode =  window.location.href;
  public customer ='/#/customer'
  public user_type = ""

  constructor(private router: Router) { }
  public openUiElements() {
    this.isOpenUiElements = !this.isOpenUiElements;
    
  }
 
  ngOnInit() {
    //getting user type 
    this.user_type =  localStorage.getItem('role');
    //alert(this.user_type )
  }

  side_redirect(to){

    //alert(this.router.url)
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([to]);
    //alert(window.location.origin +'/#/' +to);
   // window.location.href = window.location.origin+'/#/'+to;
    //return this.router.navigate(['/'+to]);
    //location.replace(window.location.origin +'/#/' +to)

  }
}
