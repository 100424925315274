import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterface } from 'src/app/shared/services/interfaces/http.interface';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  constructor(public http: HttpClient) { }


  public get_job_ids(params):Observable<any>
  {
    return this.http.post(environment.baseURL + "/admin-job-number-job-name"+ environment.api_postfix,params,{});
  }

  public load_status_list():Observable<any>{
    return this.http.post(environment.baseURL + "/job-status"+ environment.api_postfix,{});
  }

  public get_job_listing(params):Observable<any>
  {
    return this.http.post(environment.baseURL + "/list-job"+ environment.api_postfix,params,{});
  }

  public get_job_details(params):Observable<any>
  {
    return this.http.post(environment.baseURL + "/view-service-instances"+ environment.api_postfix,params,{});
  }

  public get_dropdown_list(params):Observable<any>
  {
    return this.http.post(environment.baseURL + "/service-ticket-dropdown"+ environment.api_postfix,params,{});
  }

  public create_service_ticket(params):Observable<any>
  {
    return this.http.post(environment.baseURL + "/create-service-ticket"+ environment.api_postfix,params,{});
  }

  public update_service_ticket(params):Observable<any>
  {
    return this.http.post(environment.baseURL + "/service-instance-status-update"+ environment.api_postfix,params,{});
  }

  public create_job(params):Observable<any>
  {
    return this.http.post(environment.baseURL + "/create-job"+ environment.api_postfix,params,{});
  }

  public view_job(params):Observable<any>
  {
    return this.http.post(environment.baseURL + "/view-job"+ environment.api_postfix,params,{});
  }

  public edit_job(params):Observable<any>
  {
    return this.http.post(environment.baseURL + "/edit-job"+ environment.api_postfix,params,{});
  }


  public job_asset_list(params):Observable<any>
  {
    return this.http.post(environment.baseURL + "/job-asset-list"+ environment.api_postfix,params,{});
  }


  public job_asset_list_status():Observable<any>
  {
    return this.http.post(environment.baseURL + "/job-assetlist-status"+ environment.api_postfix,{});
  }

  public get_more_info_list(params):Observable<any>
  {
    return this.http.post(environment.baseURL + "/job-more-info"+ environment.api_postfix,params,{});
  }
 
  
}
