import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerComponent } from './components/customer/customer.component';


import { MatTableModule } from '@angular/material/table';
import { NgxEchartsModule } from 'ngx-echarts';
import { TrendModule } from 'ngx-trend';
import {  MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { NgApexchartsModule } from 'ng-apexcharts';
import { FormsModule } from '@angular/forms';

import { NgxImageZoomModule } from "ngx-image-zoom"; // <-- Add this line

import { SharedModule } from '../../shared/shared.module';

import { CustomerleftwindowComponent } from './components/customer/customerleftwindow/customerleftwindow/customerleftwindow.component';
import { JobComponent, DialogSingleImage } from '../../pages/job/job.component';


@NgModule({
  declarations: [
    CustomerComponent,  
    CustomerleftwindowComponent,
    JobComponent,
    DialogSingleImage
    ],

  imports: [
    CommonModule,
    NgxImageZoomModule,
    MatTableModule,
    NgxEchartsModule,
    TrendModule,
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatProgressBarModule,
    MatToolbarModule,
    MatGridListModule,
    MatSelectModule,
    MatInputModule,
    NgApexchartsModule,
    FormsModule,
    SharedModule,
   
  ]
})
export class CustomerModule { }
