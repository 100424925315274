<div class="dashboardPage">
  <mat-card class="selectsDv">
    <form [formGroup]="dashboardForm" class="row selectsForm dashFrms">

      <mat-form-field class="col-2" appearance="fill">
        <input type="text" class="form-control" placeholder="Search Customer" aria-label="Number" matInput
          [formControl]="myControl" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="search($event,'customer',$event.option.value)"
          [displayWith]="getCustomerId.bind(this)">
          <mat-option *ngIf="isCustLoading" class="is-loading"><mat-spinner diameter="50"></mat-spinner></mat-option>
          <mat-option *ngFor="let option of customerList" [value]="option.CustomerId">
            {{option.CustomerName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>


      <!-- <mat-form-field class="col-2">
        <mat-select [(value)]="customerSeleted"  (selectionChange)="search($event,'customer')" formControlName="customer">
          
          <mat-option value=""> Customer</mat-option>
          <mat-option *ngFor="let item of customer_list" value="{{item.CustomerId}}"> {{item.CustomerName}}</mat-option>
         
        </mat-select>
      </mat-form-field> -->

      <mat-form-field class="col-2">
        <mat-select [(value)]="statusSelected" formControlName="job_status"
          (selectionChange)="search($event , 'job_status')">
          <mat-option value=""> Job Status</mat-option>
          <mat-option *ngFor="let item of jobStatus" value="{{item.JobStatusId}}"> {{item.JobStatus}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="col-2">
        <mat-select [(value)]="yearSelected" formControlName="job_year" (selectionChange)="search($event,'job_year')">
          <mat-option value=""> Job Year</mat-option>
          <mat-option *ngFor="let item of jobyears" value="{{item}}"> {{item}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="col-2">
        <mat-select [(value)]="monthSelected" formControlName="job_month"
          (selectionChange)="search($event,'job_month')">
          <mat-option value=""> Job Month</mat-option>
          <mat-option value="01"> January</mat-option>
          <mat-option value="02"> February</mat-option>
          <mat-option value="03"> March</mat-option>
          <mat-option value="04"> April</mat-option>
          <mat-option value="05"> May</mat-option>
          <mat-option value="06"> June</mat-option>
          <mat-option value="07"> July</mat-option>
          <mat-option value="08"> August</mat-option>
          <mat-option value="09"> September</mat-option>
          <mat-option value="10"> October</mat-option>
          <mat-option value="11"> November</mat-option>
          <mat-option value="12"> December</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="col-2">
        <mat-select [(value)]="jobNumberSelected" formControlName="job_nuumber"
          (selectionChange)="search($event,'job_nuumber')">
          <mat-option value=""> Job Number</mat-option>
          <mat-option *ngFor="let item of job_name_number" value="{{item.job_id}}"> {{item.job_number}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="col-2">
        <mat-select [(value)]="jobNameSelected" formControlName="job_name"
          (selectionChange)="search($event,'job_name')">
          <mat-option value=""> Job Name</mat-option>
          <mat-option *ngFor="let item of job_name_number" value="{{item.job_id}}"> {{item.job_name}}</mat-option>
        </mat-select>
      </mat-form-field>


    </form>
  </mat-card>
  <!-- <mat-card class="selectsDv selectsDvData">
    <div class="row" *ngIf="no_data_message==''">
     
      <div class="col-2"> <span>{{ customerSelectedText }}</span></div>
      <div class="col-2"><span>{{ jobStatusSelectedText }}</span></div>
      <div class="col-2"><span>{{ jobYearSelectedText }}</span></div>
      <div class="col-2"><span>{{ jobMonthSelectedText }}</span></div>
      <div class="col-2"><span>{{ jobNumberSelectedText }}</span></div>
      <div class="col-2"><span>{{ jobNameSelectedText }}</span></div>
    </div>
    <div class="row">
      <div class="col-2"> <span>{{ no_data_message }}</span></div>
    </div>
  </mat-card> -->
  <mat-card class="graph" *ngIf="showPie == 'true'">
    <div class="graph_top">
      <div class="row">
        <div class="col-md-6">
          <div class="graph_top_left">
            Total Assets: {{this.total_asset_count}}
          </div>
        </div>
        <div class="col-md-6 text-right">
          <div class="graph_top_right">
            Total Assets in Job: {{this.asset_in_job}}
          </div>
        </div>
      </div>
    </div>
   <mat-card class="card graphCard">
      <div class="row d-flex align-items-center">
        <div class="col-md-6">
          <div class="graph_left">
            <div class="percentList">
              <span>Assigned: {{this.assigned}}</span>
              <span class="color black"></span>
            </div>
            <div class="percentList">
              <span>Field Complete: {{this.field_complete}} </span>
              <span class="color teal"></span>
            </div>
            <div class="percentList">
              <span> Approved: {{this.approved}} </span>
              <span class="color gray"></span>
            </div>
            <div class="percentList">
              <span>Cancelled - Billable: {{this.cancelled_billable}} </span>
              <span class="color orrange"></span>
            </div>
            <div class="percentList">
              <span>Cancelled - Non--Billable: {{this.cancelled_non_billable}} </span>
              <span class="color red"></span>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="charts-wrapper">
            <div class="chart">
              <app-revenue-chart [revenueCharData]="revenueChartData$"></app-revenue-chart>
            </div>
          </div>
        </div>
      </div>
   </mat-card>
  </mat-card>
  <mat-card class="tblDv">

    <!-- spinner -->

    <div *ngIf="isLoading" style="
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0,0,0,0.6);
  width: 100%;
  height: 100%;
">
      <mat-spinner color="primary"></mat-spinner>
    </div>

    <!-- spinner end -->

    <div class="">



      <table mat-table [dataSource]="dataSource" *ngIf="showPie == 'true'">
        <!-- Position Column -->
        <ng-container matColumnDef="assetid">
          <th mat-header-cell *matHeaderCellDef>Asset Id</th>
          <td mat-cell *matCellDef="let element">{{ element.atm_id }}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="atmdescription">
          <th mat-header-cell *matHeaderCellDef>Atm Description</th>
          <td mat-cell *matCellDef="let element">{{ element.atm_description }}</td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef>Address</th>
          <td mat-cell *matCellDef="let element">{{ element.address }}</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef>City</th>
          <td mat-cell *matCellDef="let element">{{ element.city }}</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef>State</th>
          <td mat-cell *matCellDef="let element">{{ element.state_code }}</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="zip">
          <th mat-header-cell *matHeaderCellDef>Zip</th>
          <td mat-cell *matCellDef="let element">{{ element.zip }}</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element"><div class="badge badgeActive">{{ element.Job_status }}</div></td>
        </ng-container>




        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <div class="height-div" *ngIf="showPie == 'false'">

      </div>
      <div *ngIf="!total_asset_count" style="text-align: center; padding-top: 16px;">No data found</div>
                        <mat-paginator *ngIf="total_asset_count" [pageSize]="5" [hidePageSize]="true" [length]="total_asset_count" [pageSizeOptions]="[5, 10, 20]"
        (page)="onPaginateChange($event)"></mat-paginator>

    </div>

  </mat-card>
</div>