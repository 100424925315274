<div class="modal-body">
  <form [formGroup]="emailForm">
    <div class="popTop">
      <img src="./assets/images/cams-logo-black.png" alt="">
      <span>Email Issue</span>
    </div>

    <div class="popMid">

      <mat-form-field class="example-full-width" appearance="fill">
        <input matInput class="popComntBox popInputBox" formControlName="email" placeholder="Email(Required)">
        <!-- <input type="text" class="popComntBox popInputBox" formControlName="email" placeholder="Email(Required)"> -->
      </mat-form-field>
      <div  *ngIf="(emailForm.controls['email'].invalid && (emailForm.controls['email'].dirty || emailForm.controls['email'].touched)) || (emailForm.controls['email'].invalid && submitted)">
        <div class="alert alert-danger" role="alert" *ngIf="emailForm.controls['email'].errors.required || emailForm.controls['email'].errors.pattern">
          Please enter a valid Email
        </div>
      </div>


      <mat-form-field class="example-full-width" appearance="fill">
        <input matInput class="popComntBox popInputBox" formControlName="subject" placeholder="Subject(Required)">
        <!-- <input type="text" class="popComntBox popInputBox" formControlName="email" placeholder="Email(Required)"> -->
      </mat-form-field>
      <div  *ngIf="(emailForm.controls['subject'].invalid && (emailForm.controls['subject'].dirty || emailForm.controls['subject'].touched)) || (emailForm.controls['subject'].invalid && submitted)">
        <div class="alert alert-danger" role="alert" *ngIf="emailForm.controls['subject'].errors.required">
          Please enter subject
        </div>
      </div>


      <p><span>Issue Type</span>:<span>{{data.IssueType}}</span></p>
      <p><span>Issue Sub Type</span>:<span>{{data.IssueSubType}}</span></p>
      <p><span>Issue Description</span>:<span>{{data.IssueDescription}}</span>
      <p>
      <p><img
          src="{{data.ImageURL}}" />
      </p>
      <mat-form-field class="example-full-width" appearance="fill">
        <!-- <mat-label>Leave a comment</mat-label> -->
        <textarea class="popComntBox" formControlName="body" matInput placeholder="Comment(Required)"></textarea>
      </mat-form-field>
      <div  *ngIf="(emailForm.controls['body'].invalid && (emailForm.controls['body'].dirty || emailForm.controls['body'].touched)) || (emailForm.controls['body'].invalid && submitted)">
        <div class="alert alert-danger" role="alert" *ngIf="emailForm.controls['body'].errors.required">
          Please enter body
        </div>
      </div>
    </div>
    <div class="popBtm">
      <button mat-button (click)="onCancel()">Cancel</button>
      <button mat-button (click)="onConfirm()">Send Email</button>
    </div>
  </form>
</div>