import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { routes } from '../../../consts';

@Injectable()
export class AuthGuard implements CanActivate{
  public routers: typeof routes = routes;

  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const token = localStorage.getItem('access_token');
    const role = localStorage.getItem('role');

    if (token && role=="SA" ||  role == "CA" || role=="CV") {
      return true;
    } else {
      this.router.navigate([this.routers.LOGIN]);
    }
  }
}

@Injectable()
export class CustGuard implements CanActivate{
  public routers: typeof routes = routes;

  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const token = localStorage.getItem('access_token');
    const role = localStorage.getItem('role');

    if (token && role=="C") {
      return true;
    } else {
      this.router.navigate([this.routers.LOGIN]);
    }
  }
}

@Injectable()
export class AdminCustGuard implements CanActivate{
  public routers: typeof routes = routes;

  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const token = localStorage.getItem('access_token');
    const role = localStorage.getItem('role');

    if (token && (role=="SA" || role=="CA" || role=="C" || role=="CV")) {
      return true;
    } else {
      this.router.navigate([this.routers.LOGIN]);
    }
  }
}