<app-layout>
  <ng-template #content let-c="close" let-d="dismiss">
    <!-- tag part start-->
    <div class="aprvlPop" id="tag_div" style="display: none;">
      <div class="modal-header">
        <div class="aprvlPopLogo">
          <img src="./assets/images/cams-logo-black.png" alt="">
        </div>
        <div class="aprvlPopedtImgDv">Edit Image Tag</div>
      </div>
      <div class="modal-body">
        <div class="shwImgDv">
          <div class="row">
            <div class="col-12">
              <div class="shwImgs">
                <div class=" Acol">
                  <label>Select Image Tag</label>
                  <select id="updateImageTAG">
                    <option value="{{imageTagrow.ImageTag}}" *ngFor="let imageTagrow of imageTagList;"
                      [selected]="imageTagrow.ImageTag == SelectedTag_name">
                      {{imageTagrow.ImageTag}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-outline-dark" (click)="c('Save click')">Save</button> -->
        <a href="javascript:void(0)" class="btnAt" (click)="ContinueWithImageTag()"><span>Continue</span></a>
      </div>
    </div>
    <!-- Tag part end -->
    <!-- Image upload part start -->
    <div class="aprvlPop" id="img_upload_div">
      <div class="modal-header">
        <!-- <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button> -->
        <div class="aprvlPopLogo">
          <img src="./assets/images/cams-logo-black.png" alt="">
        </div>
        <div class="aprvlPopedtImgDv">Edit Image</div>
      </div>
      <div class="modal-body">
        <!--<div class="aprvlPopSlider">
              <div class="row">
                <div class="popthumbs">
                  <div class="popThumb">
                    <img src="./assets/images/img1.jpg" alt="">
                  </div>
                  <div class="popThumb">
                    <img src="./assets/images/img1.jpg" alt="">
                  </div>
                  <div class="popThumb">
                    <img src="./assets/images/img1.jpg" alt="">
                  </div>
                  <div class="popThumb">
                    <img src="./assets/images/img1.jpg" alt="">
                  </div>
                  <div class="popThumb">
                    <img src="./assets/images/img1.jpg" alt="">
                  </div>
                  <div class="popThumb">
                    <img src="./assets/images/img1.jpg" alt="">
                  </div>
                </div>
                <a href="#" role="button" data-slide="prev" class="carousel-control-prev">
                  <span aria-hidden="true" class="carousel-control-prev-icon">
                    <img src="./assets/images/bck.png" alt="">
                  </span>
                </a>
                <a href="#" role="button" data-slide="next" class="carousel-control-next">
                  <span aria-hidden="true" class="carousel-control-next-icon">
                    <img src="./assets/images/nxt.png" alt="">
                  </span>
                </a>
              </div>
              </div>-->
        <div class="shwImgDv">
          <div class="row">
            <div>
              <input type="file" id="my_file" #myInput accept="image/png, image/gif, image/jpeg, image/jpg"
                (change)="fileChangeEvent($event)" style="display: none;">
              <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false"
                [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="4 / 3" [resizeToWidth]="256"
                [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="false" [canvasRotation]="canvasRotation"
                [transform]="transform" [alignImage]="'left'" [style.display]="showCropper ? null : 'none'" format="png"
                (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()">
              </image-cropper>
              <br>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              
            </div>
            <div class="col-6">
              <div class="shwImgs {{imagebase64NotShow}}">
                <img [src]="croppedImage" alt="" id="myImage">
              </div>
              <div class="shwImgs {{imagebase64Updated}}">
                <canvas class="canvas_class" id="c"></canvas>
              </div>

            </div>
            <div class="col-6">
              <div class="imgBtns">

                <div class="imgBtn" *ngIf="imageBase64 != ''"><img src="./assets/images/rac.png" alt=""><a
                    href="javascript:void(0)" (click)="existing_rotate_left()" class="btnAt"><span>ROTATE
                      LEFT</span></a>
                </div>
                <div class="imgBtn" *ngIf="imageBase64 != ''"><img src="./assets/images/rc.png" alt=""><a
                    href="javascript:void(0)" (click)="existing_rotate_right()" class="btnAt"><span>ROTATE
                      RIGHT</span></a>
                </div>


                <div class="imgBtn"><img src="./assets/images/upload.png" alt=""><a href="javascript:void(0)"
                    (click)="selectImgFile()"
                    class="btnAt {{Image_upload_btn_status}}"><span>{{UploadBtnText|uppercase }}</span></a></div>
                

              </div>
            </div>
            <div class="loaderDvPop">
              <div class="loader_img" *ngIf="updateLoader">
                <center><img src="./assets/images/basic-loader.gif"></center>
              </div>
            </div>
          </div>
        </div>
      </div>
       <div class="modal-footer">
        <a href="javascript:void(0)" class="btnAt" aria-label="Close" (click)="closepopup()"><span>CANCEL</span></a>
        <a href="javascript:void(0)" class="btnAt {{Base64_Image_update_btn_status}}"
          (click)="updateImage(content)"><span>SAVE CHANGES</span></a>
      </div>
    </div>
    <!-- Image upload part start -->
  </ng-template>
  <ng-template #content_tag let-c="close" let-d="dismiss">
    <!-- tag part start-->
    <div class="aprvlPop" id="tag_div" style="display: block;">
      <div class="modal-header">
        <div class="aprvlPopLogo">
          <img src="./assets/images/axis-logo-black-curved.png" alt="">
        </div>
        <div class="aprvlPopedtImgDv">Edit Image Tag</div>
      </div>
      <div class="modal-body">
        <div class="shwImgDv">
          <div class="row">
            <div class="col-12">
              <div class="shwImgs">
                <div class=" Acol" *ngIf="imageTagList.length > 0">
                  <label>Select Image Tag</label>
                  <select id="updateImageTAGInd">
                    <option value="{{imageTagrow.ImageTag}}" *ngFor="let imageTagrow of imageTagList;"
                      [selected]="imageTagrow.ImageTag == SelectedTag_name">
                      {{imageTagrow.ImageTag}}</option>
                  </select>
                </div>
              </div>
              <div class="loaderDvPop">
                <div class="loader_img" *ngIf="imageTagList.length === 0">
                  <center><img src="./assets/images/basic-loader.gif"></center>
                </div>
                <div class="loader_img" *ngIf="tagUpdateloader">
                  <center><img src="./assets/images/basic-loader.gif"></center>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a href="javascript:void(0)" class="btnAt" aria-label="Close" (click)="closepopup()"><span>CLOSE</span></a>
        <a href="javascript:void(0)" *ngIf="imageTagList.length === 0" class="disabled-btn btnAt"><span>SAVE
            CHANGES</span></a>
        <a href="javascript:void(0)" *ngIf="imageTagList.length > 0" class="btnAt {{tagUpdateBtnclass}}"
          (click)="ImageTagUpdate()"><span>SAVE CHANGES</span></a>
      </div>
    </div>
  </ng-template>
  <ng-template #content_answer let-c="close" let-d="dismiss">

    <div class="aprvlPop" id="tag_div" style="display: block;">
      <div class="modal-header">
        <div class="aprvlPopLogo">
          <img src="./assets/images/cams-logo-black.png" alt="">
        </div>
        <div class="aprvlPopedtImgDv">Select Images</div>
      </div>
      <div class="modal-body">
        <div class="shwImgDv">
          <div class="row">
            <div class="col-12">
              <div class="shwImgs">
                <div class=" Acol img-tag">
                  <!-- {{answerTypeValueId | json}} -->
                  <accordion [closeOthers]="true">
                    <accordion-group *ngFor="let ans of answerTypeValueId" [heading]="ans.ImageTag">
                      <div>
                        <span *ngFor="let ansImg of answerTypeImages">
                          <input (click)="assignImage(ans.DynamicImageTagID,ansImg.imageId)"
                            *ngIf="ansImg.DynamicImageTagID == '' || ansImg.DynamicImageTagID == ans.DynamicImageTagID"
                            type="radio" [checked]="ansImg.is_selected" name="image_{{ans.DynamicImageTagID}}"
                            id="{{ans.DynamicImageTagID}}_{{ansImg.imageId}}" class="input-hidden" />
                          <label
                            *ngIf="ansImg.DynamicImageTagID == '' || ansImg.DynamicImageTagID == ans.DynamicImageTagID"
                            for="{{ans.DynamicImageTagID}}_{{ansImg.imageId}}">
                            <img src="{{ansImg.publicUrl}}" alt="Loading..." />
                          </label>
                        </span>

                        <input type="file" name="emotion" id="file-upload-{{ans.DynamicImageTagID}}" (change)="onFileChange($event,ans.DynamicImageTagID)"
                          class="input-hidden" />
                        <label for="file-upload-{{ans.DynamicImageTagID}}" title="Click to upload image">
                          <img src="//static.thenounproject.com/png/513228-200.png" alt="I'm happy" />
                        </label>

                      </div>
                    </accordion-group>
                    <!-- <accordion-group heading="Initially expanded"
                                     [isOpen]="isFirstOpen">
                      This content is straight in the template.
                    </accordion-group>
                    <accordion-group heading="Content 2">
                      <p>accordion 3</p>
                    </accordion-group> -->
                  </accordion>





                </div>
              </div>
              <div class="loaderDvPop">
                <div class="loader_img" *ngIf="isImageUpload">
                  <center><img src="./assets/images/basic-loader.gif"></center>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a href="javascript:void(0)" class="btnAt" aria-label="Close" (click)="closepopup('multi-image')"><span>CLOSE</span></a>
        <a href="javascript:void(0)" (click)="saveImageAnswer(answerTypeValueId)" class="btnAt"><span>SAVE CHANGES</span></a>
       
      </div>
    </div>

  </ng-template>

  <div class="customer_Right approval_Right">
    <div *ngIf="isLoading" style="
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        background-color: rgba(0, 0, 0, 0.6);
        width: 100%;
        height: 100%;
        z-index: 999999999999999999;
        ">
      <mat-spinner color="primary"></mat-spinner>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="approValToolSec d-flex">
          <div class="tabPanelSec w-100">
            <mat-tab-group [(selectedIndex)]="nonClickTabIndex">
              <mat-tab label="{{totalrecordFound}} ASSETS" class="nonClck"> </mat-tab>
              <mat-tab label="Approval Queue">
                <div class="selectRowDv d-flex">
                  <div class="Slctcol">
                    <select class="slctDd" #jobCustomerSelect
                      (change)="filterByDataCollect(jobCustomerSelect.value, 'job_customer')">
                      <option value="">Customer</option>
                      <option *ngFor="let customerItem of customerLookup" value="{{customerItem.CustomerId}}">
                        {{customerItem.CustomerName}}</option>
                    </select>
                  </div>
                  <div class="Slctcol">
                    <select class="slctDd" #jobStatusSelect
                      (change)="filterByDataCollect(jobStatusSelect.value, 'job_status')">
                      <option value="">Job Status</option>
                      <option *ngFor="let jobStatusItem of jobStatusLookup" value="{{jobStatusItem.job_status_id}}">
                        {{jobStatusItem.job_status}}
                      </option>
                    </select>
                  </div>
                  <div class="Slctcol">
                    <select class="slctDd" #jobYearSelect
                      (change)="filterByDataCollect(jobYearSelect.value, 'job_year')">
                      <option value="">Job Year</option>
                      <option *ngFor="let jobYearItem of jobYearLookup" value="{{jobYearItem}}"> {{jobYearItem}}
                      </option>
                    </select>
                  </div>
                  <div class="Slctcol">
                    <select class="slctDd" #jobMonthSelect
                      (change)="filterByDataCollect(jobMonthSelect.value, 'job_month')">
                      <option value=""> Job Month</option>
                      <option value="01"> January</option>
                      <option value="02"> February</option>
                      <option value="03"> March</option>
                      <option value="04"> April</option>
                      <option value="05"> May</option>
                      <option value="06"> June</option>
                      <option value="07"> July</option>
                      <option value="08"> August</option>
                      <option value="09"> September</option>
                      <option value="10"> October</option>
                      <option value="11"> November</option>
                      <option value="12"> December</option>
                    </select>
                  </div>
                  <div class="Slctcol">
                    <select class="slctDd" #jobNumberSelect
                      (change)="filterByDataCollect(jobNumberSelect.value, 'job_number')">
                      <option value="">Job Number</option>
                      <option *ngFor="let jobIdItem of jobIdLookup" value="{{jobIdItem.job_number}}">
                        {{jobIdItem.job_number}}
                      </option>
                    </select>
                  </div>
                  <!--<div class="Slctcol">
                       <select class="slctDd">
                         <option value="">Job Year</option>
                       </select>
                       </div>-->
                </div>
                <div class="tableDv approvalTblDv">
                  <table matSort (matSortChange)="sortData($event)">
                    <tr>
                      <th mat-sort-header="idn">Asset ID</th>
                      <th mat-sort-header="description">ATM Description</th>
                      <th mat-sort-header="address">Address</th>
                      <th mat-sort-header="city">City</th>
                      <th mat-sort-header="state">State</th>
                      <th mat-sort-header="zip">Zip</th>
                      <th mat-sort-header="date">Service Date</th>
                      <th mat-sort-header="services">Services</th>
                    </tr>
                    <tr *ngFor="let at of sortedData">
                      <td>
                        <a title="{{ at.idn }}" href="javascript:void(0)" (click)="openApprovalDetails(at)">{{ at.idn
                          }}</a>
                      </td>
                      <td>{{ at.desp }}</td>
                      <td>{{ at.address }}</td>
                      <td>{{ at.city }}</td>
                      <td>{{ at.state }}</td>
                      <td>{{ at.zip }}</td>
                      <td>{{changeDateFormat(at.date)}}</td>
                      <td>{{ at.services }}</td>
                    </tr>
                  </table>
                  <!-- <div class="row" *ngIf="!sortedData.length"> -->
                  <div class="col-12" class="no-data-text" *ngIf="!sortedData.length"> <span>No Data Available</span>
                  </div>
                  <!--  </div> -->
                </div>
              </mat-tab>
              <mat-tab label="Approval Tool" class="nonClck">
                <div class="atTop atTopCustom card">
                  <div class="row">
                    <div class="col-md-3 d-flex">
                      <div class="card colDvCrd">
                        <h5>Asset ID</h5>
                        <span>{{jobDetailsBasic.idn}}</span>
                      </div>
                    </div>
                    <div class="col-md-3 d-flex">
                      <div class="card colDvCrd">
                        <h5>ATM Description</h5>
                        <span>{{jobDetailsBasic.desp}}</span>
                      </div>
                    </div>
                    <div class="col-md-3 d-flex">
                      <div class="card colDvCrd">
                        <h5>Address</h5>
                        <span>{{jobDetailsBasic.address}}</span>
                      </div>
                    </div>
                    <div class="col-md-3 d-flex">
                      <div class="card colDvCrd">
                        <h5>City</h5>
                        <span>{{jobDetailsBasic.city}}</span>
                      </div>
                    </div>
                    <div class="col-md-3 d-flex">
                      <div class="card colDvCrd">
                        <h5>State</h5>
                        <span>{{jobDetailsBasic.state}}</span>
                      </div>
                    </div>
                    <div class="col-md-3 d-flex">
                      <div class="card colDvCrd">
                        <h5>Zip</h5>
                        <span>{{jobDetailsBasic.zip}}</span>
                      </div>
                    </div>
                    <div class="col-md-3 d-flex">
                      <div class="card colDvCrd">
                        <h5>Service Date</h5>
                        <span>{{changeDateFormat(jobDetailsBasic.date)}}</span>
                      </div>
                    </div>
                    <div class="col-md-3 d-flex">
                      <div class="card colDvCrd">
                        <h5>Services</h5>
                        <span>{{jobDetailsBasic.services}}</span>
                      </div>
                    </div>
                    <!-- <div class="col-md-4">{{jobDetailsBasic.desp}}</div>
                    <div class="col-md-4">{{jobDetailsBasic.address}}</div>
                    <div class="col-md-4">{{jobDetailsBasic.city}}</div>
                    <div class="col-md-4">{{jobDetailsBasic.state}}</div>
                    <div class="col-md-4">{{jobDetailsBasic.zip}}</div>
                    <div class="col-md-4">{{jobDetailsBasic.date}}</div>
                    <div class="col-md-4">{{jobDetailsBasic.services}}</div> -->
                  </div>
                </div>
                <div class="threeColRow">
                  <div class="row">
                    <div class="col-md-5 bdrCol">
                      <!--<div class="atSliderCol">
                             <img src="./assets/images/slider.png" alt="" />
                             </div>-->
                      <div class="atGalDv">
                        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                          <div class="carousel-inner">


                            <ng-container *ngIf="
                                      IgnoreDetails.slider_images.length > 0;
                                      then showProfileSlider;
                                      else dontShowProfileSlider
                                      ">
                            </ng-container>
                            <ng-template #showProfileSlider>
                              <div class="caro_container">
                                <div class="carousel-container position-relative row">
                                  <div class="col-md-4 col-lg-4">
                                    <div id="myCarousel" class="carousel slide galImg" data-ride="carousel">
                                      <div class="carousel-inner">
                                        <div [ngClass]="
                                                     i == 0
                                                     ? 'carousel-item active'
                                                     : 'carousel-item'
                                                     " *ngFor="
                                                     let item of IgnoreDetails.slider_images;
                                                     let i = index
                                                     " [attr.data-slide-number]="i">
                                          <img (click)="historyImageView(item[0]['public_url'])"
                                            style="height: 128px;width: 128px !important; cursor: pointer"
                                            class="d-block w-100" src="{{ item[0]['public_url']}}?t={{randNumber}}" alt="{{ i }} slide" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-8 col-lg-8">
                                    <div id="carousel-thumbs" class="carousel slide thumbImgs" data-ride="carousel"
                                      data-interval="0">
                                      <div class="carousel-inner">
                                        <div [ngClass]="
                                                  j == 0
                                                  ? 'carousel-item active'
                                                  : 'carousel-item'
                                                  " *ngFor="
                                                  let itemThumbList of IgnoreDetails.image_thumb_list;
                                                  let j = index
                                                  ">
                                          <div class="row mx-0">
                                            <div *ngFor="
                                                     let thumb of itemThumbList;
                                                     let i = index
                                                     " id="carousel-selector-{{
                                                     thumb.id
                                                     }}"
                                              class="thumb col-4 col-sm-2 px-1 py-2 remove-selection-thumb-slider"
                                              data-target="#myCarousel" [attr.data-slide-to]="thumb.id" (click)="
                                                     showSliderThumb(thumb.id)
                                                     ">
                                              <img style="height: 48px;width: 48px !important;" src="{{thumb.item[0]['public_url']}}?t={{randNumber}}"
                                                alt="{{ thumb.id }} slide-thumb" class="img-fluid" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <a class="carousel-control-prev" href="#carousel-thumbs" role="button"
                                        data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true">
                                          <img src="./assets/images/bck.png" alt="">
                                        </span>
                                        <!-- <span class="sr-only">Previous</span> -->
                                      </a>
                                      <a class="carousel-control-next" href="#carousel-thumbs" role="button"
                                        data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true">
                                          <img src="./assets/images/nxt.png" alt="">
                                        </span>
                                        <!-- <span class="sr-only">Next</span> -->
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-template>
                            <ng-template #dontShowProfileSlider>
                              <div>
                                <p>No Image Added</p>
                              </div>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 bdrCol">
                      <div class="detailsColService d-flex justify-content-center align-items-center h-100">
                        <div class="detailsContDv">
                          <p><strong>Technician:</strong> <span>{{IgnoreDetails.technicianDetails.HostName}}</span></p>
                          <p><strong>Service Time:</strong> <span>{{IgnoreDetails.technicianDetails.FieldComplete}}</span></p>
                          <p><strong>Job Name:</strong>
                            <span>{{IgnoreDetails.technicianDetails.JobName}}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3" *ngIf="!isViewOnly">
                      <div class="aprvReasgn d-flex justify-content-center align-items-center h-100">
                        <div class="aprvReasgnContDv">
                          <a href="javascript:void(0)" (click)="approve_item(jobDetailsBasic.ServiceInstanceId)"
                            class="btnAt">APPROVE</a>
                          <!-- <a href="javascript:void(0)" class="btnAt">REASSIGN</a> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="atQARow">
                  <table class="questiontable" cellpadding="0" cellspacing="0"
                    *ngFor="let summaryRow of IgnoreDetails.summary_list;  let q = index; let slno = index">






                    <thead>

                      <tr class="mb-15">
                        <th class="question-block" colspan="1">{{slno+1}}. {{summaryRow.Question}}</th>
                      <tr>
                    </thead>
                    <tr>


                      <td>

                        <table border="0" cellpadding="0" cellspacing="0">
                          <tr>
                            <td>
                              <table style="width: 60%;">
                                <tr style="margin-bottom: 13px;">
                                  <td style="font-weight: 700">Answer:</td>
                                  <td><span>{{summaryRow.answer_description}}</span>
                                    <a *ngIf="summaryRow.AnswerType.trim() == 'Radio'" href="javascript:void(0)"><img style="margin-left: 10px;height: 20px;"
                                        src="./assets/images/editIcon.png"
                                        (click)="Showselection(summaryRow.QuestionId)"></a>
                                    <br><br>

                                    <!-- For Checkbox -->
                                    <div class="showAnswer" *ngIf="showAnswer == summaryRow.QuestionId">
                                      <!-- For Checkbox -->
                                      <div class=" Acol" *ngIf="summaryRow.AnswerType.trim() === 'Checkbox'">
                                        <section class="example-section">
                                          <mat-checkbox *ngFor="let optionsRow of summaryRow.options;"
                                            value="{{optionsRow.Answertypevalueid}}" class="example-margin"
                                            [checked]="checkSelected(optionsRow.Answertypevalueid, summaryRow.answer_type_value_id) === true">{{optionsRow.AnswerDescription}}</mat-checkbox>
                                        </section>
                                      </div>


                                      <!-- For Yes/No -->
                                      <div class=" Acol" *ngIf="summaryRow.AnswerType.trim() === 'Yes/No'">
                                        <select id="yes_no_{{summaryRow.QuestionId}}"
                                          (change)="onItemChange('yes/no', '', summaryRow,'select',$event.target.value)">
                                          <option value="{{optionsRow.Answertypevalueid}}"
                                            *ngFor="let optionsRow of summaryRow.options;"
                                            [selected]="optionsRow.Answertypevalueid == summaryRow.answer_type_value_id">
                                            {{optionsRow.AnswerDescription}}
                                          </option>
                                        </select>

                                      </div>

                                      <!-- For Radio -->
                                      <div class=" Acol" *ngIf="summaryRow.AnswerType.trim() === 'Radio'">

                                        <span *ngFor="let optionsRow of summaryRow.options;">
                                          <input (change)="changeAnswer(summaryRow, content_answer,optionsRow.Answertypevalueid)" type="radio"
                                            name="ansOptRadio_{{summaryRow.QuestionId}}"
                                            value="{{optionsRow.Answertypevalueid}}"
                                            [checked]="optionsRow.Answertypevalueid == summaryRow.answer_type_value_id">
                                          {{optionsRow.AnswerDescription}}<br>
                                        </span>

                                      </div>

                                      <!-- For Textbox -->
                                      <div class=" Acol" *ngIf="summaryRow.AnswerType.trim() === 'Text'">
                                        <input type="text" placeholder="Comment" id="comments_{{summaryRow.QuestionId}}"
                                          value="{{summaryRow.Comments}}" style="width: 75%;">
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr style="margin-bottom: 13px;">
                                  <td style="font-weight: 700">Comment:</td>
                                  <td>
                                    <span>{{summaryRow.Comments}}</span>
                                    <!-- <a href="javascript:void(0)"><img style="margin-left: 10px;height: 20px;"
                                        src="./assets/images/editIcon.png"
                                        (click)="ShowsComment(summaryRow.QuestionId)"></a> -->

                                    <div class="showComment" *ngIf="showComment == summaryRow.QuestionId">
                                      <input type="text" placeholder="Comment" id="comments_{{summaryRow.QuestionId}}"
                                        value="{{summaryRow.Comments}}"
                                        (blur)="onItemChange('yes/no', '', summaryRow,'textbox',$event.target.value)"
                                        style="width: 75%;">
                                    </div>
                                  </td>
                                </tr>

                              </table>
                            </td>
                            <!-- <td> -->
                            <!-- For Yes/No -->
                            <!-- <div class=" Acol" *ngIf="summaryRow.AnswerType.trim() === 'Yes/No'">
                                <select id="yes_no_{{summaryRow.QuestionId}}"
                                  (change)="onItemChange('yes/no', '', summaryRow,'select',$event.target.value)">
                                  <option value="{{optionsRow.Answertypevalueid}}"
                                    *ngFor="let optionsRow of summaryRow.options;"
                                    [selected]="optionsRow.Answertypevalueid == summaryRow.answer_type_value_id">
                                    {{optionsRow.AnswerDescription}}</option>
                                </select>
                                <br><br>
                                <input type="text" placeholder="Comment" class="cmnt"
                                  id="comments_{{summaryRow.QuestionId}}" value="{{summaryRow.Comments}}"
                                  (blur)="onItemChange('yes/no', '', summaryRow,'textbox',$event.target.value)"
                                  style="width: 75%;">
                              </div> -->
                            <!-- For Radio -->
                            <!-- <div class=" Acol" *ngIf="summaryRow.AnswerType.trim() === 'Radio'">
                                
                               <span *ngFor="let optionsRow of summaryRow.options;">
                                  <input type="radio" name="ansOptRadio_{{summaryRow.QuestionId}}"
                                    value="{{optionsRow.Answertypevalueid}}"
                                    [checked]="optionsRow.Answertypevalueid == summaryRow.answer_type_value_id"
                                    (change)="onItemChange('radio', '', summaryRow,'radio',$event.target.value)">
                                  {{optionsRow.AnswerDescription}}<br>
                                </span> 
                                
                                <br><br>
                                <input type="text" placeholder="Comment" class="cmnt"
                                  id="comments_{{summaryRow.QuestionId}}" value="{{summaryRow.Comments}}"
                                  (blur)="onItemChange('radio', '', summaryRow,'textbox',$event.target.value)"
                                  style="width: 75%;">
                              </div> -->
                            <!-- For Checkbox -->
                            <!-- <div class=" Acol" *ngIf="summaryRow.AnswerType.trim() === 'Checkbox'">
                                <select id="MultiSelect_{{summaryRow.QuestionId}}" class="largSelect"
                                  (change)="onItemChange('checkbox', '', summaryRow,'select',$event.target.value)">
                                  <option value="{{optionsRow.Answertypevalueid}}"
                                    *ngFor="let optionsRow of summaryRow.options;"
                                    [selected]="checkSelected(optionsRow.Answertypevalueid, summaryRow.answer_type_value_id) == true">
                                    {{optionsRow.AnswerDescription}}</option>
                                </select>
                                <br><br>
                                <input type="text" placeholder="Comment" class="cmnt"
                                  id="comments_{{summaryRow.QuestionId}}" value="{{summaryRow.Comments}}"
                                  (blur)="onItemChange('checkbox', '', summaryRow,'textbox',$event.target.value)"
                                  style="width: 75%;">
                              </div> -->
                            <!-- For Textbox -->
                            <!--  <div class=" Acol" *ngIf="summaryRow.AnswerType.trim() === 'Text'">
                                <input type="text" placeholder="Comment" id="comments_{{summaryRow.QuestionId}}"
                                  value="{{summaryRow.Comments}}"
                                  (blur)="onItemChange('text', '', summaryRow,'textbox',$event.target.value)"
                                  style="width: 75%;">
                              </div>
                            </td> -->


                            <td>
                              <div class="rowImage">
                                <div *ngIf="summaryRow.question_images.length ==  0">__</div>
                                <div style="width: 100%;" *ngIf="summaryRow.question_images.length > 0">

                                  <!-- ------------------------Question Slider-------------- -->
                                  <div class="atGalDv">
                                    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                                      <div class="carousel-inner">


                                        <ng-container *ngIf="
                                                    IgnoreDetails.slider_images.length > 0;
                                                    then showProfileSlider;
                                                    else dontShowProfileSlider
                                                    ">
                                        </ng-container>
                                        <ng-template #showProfileSlider>
                                          <div class="caro_container">
                                            <div
                                              class="carousel-container position-relative row justify-content-center">
                                              <div class="col-md-12 col-lg-12">
                                                <center>
                                                  <div id="myCarousel" class="carousel slide galImg"
                                                    data-ride="carousel">
                                                    <div class="carousel-inner">

                                                      <div class="atprevwImgDv">
                                                        <img id="image-{{summaryRow.QuestionId}}"
                                                          (click)="historyImageViewAnswer(summaryRow)" class="d-block w-100"
                                                          src="{{summaryRow.question_images[0].publicUrl}}?t={{randNumber}}"
                                                          alt=" slide" />

                                                        <p id="image-tag-{{summaryRow.QuestionId}}" class="mt-3 mb-3">
                                                          {{summaryRow.question_images[0].ImageTag}}</p>
                                                      </div>



                                                    </div>
                                                  </div>
                                                </center>
                                              </div>
                                              <div class="col-md-12 col-lg-12 d-flex justify-content-center ">
                                                <div class="slickThumbSlide">

                                                  <ngx-slick-carousel class="carousel" #slickModal="slick-carousel"
                                                    [config]="slideConfig" (init)="slickInit($event)"
                                                    (breakpoint)="breakpoint($event)"
                                                    (afterChange)="afterChange($event)"
                                                    (beforeChange)="beforeChange($event)">
                                                    <div ngxSlickItem *ngFor="let img of  summaryRow.question_images"
                                                      class="slide">
                                                      <img
                                                        (click)="changeMainImage(img.publicUrl,img.ImageTag,summaryRow)"
                                                        src="{{img.publicUrl}}?t={{randNumber}}" alt="" width="48px">

                                                    </div>
                                                  </ngx-slick-carousel>

                                                </div>


                                              </div>
                                            </div>
                                          </div>
                                        </ng-template>
                                        <ng-template #dontShowProfileSlider>
                                          <div>
                                            <p>No Image Added</p>
                                          </div>
                                        </ng-template>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- ------------------------------------------------------- -->

                                  <!-- {{summaryRow|json}} -->
                                  <!-- <img 
                                        (click)="historyImageView(summaryRow)" 
                                        style="cursor: pointer" 
                                        id="list_image_{{summaryRow.QuestionId}}" 
                                        src="{{ baseUrl }}images/{{  summaryRow.JobId }}/{{ summaryRow.ATMID }}/{{  summaryRow.ImageId }}.{{ summaryRow.Extension }}?t={{randNumber}}" alt="" 
                                      /> -->
                                </div>

                                <div class="atEdit" style="margin-left: 20px" *ngIf="!isViewOnly">
                                  <a  *ngIf="summaryRow.question_images.length > 0" href="javascript:void(0)" (click)="editImageAnswer(summaryRow, content)"><img
                                      src="./assets/images/editIcon.png" alt="" /></a>
                                </div>
                              </div>


                            </td>

                          </tr>

                        </table>


                      </td>
                    </tr>









                  </table>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</app-layout>